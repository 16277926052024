<template>
	<div class="optional-items">
		<div @click="$refs.optionalSettingsDialog.open()"
			style="background: #fafafa; border-bottom: 1px solid #eee; border-top: 1px solid #eee; color: gray; font-size: smaller; margin-bottom: 20px; padding: 8px 24px; margin: -8px -24px 20px -24px; cursor: pointer;"
		>
			<v-icon>mdi-cog</v-icon>
			{{ $t('text.additionalSettingsCustomize') }}
		</div>
		<Optional id="validitySettings" v-model="optionalSettings" startEnabled>
			<FieldSet id="validitySettings" required>
				<Field typeName="AddedValue" fieldName="validityType" v-model="modelValue.fields.validityType" />
				<div style="display: flex; gap: 10px;" :class="{
					disabled: modelValue.fields.validityType?.de != 'more',
				}">
					<Field typeName="AddedValue" fieldName="validDaysBefore" v-model="modelValue.fields.validDaysBefore" style="flex: 1;" />
					<Field typeName="AddedValue" fieldName="validDaysAfter" v-model="modelValue.fields.validDaysAfter" style="flex: 1;" />
				</div>
			</FieldSet>
		</Optional>

		<Optional id="seasonalDiscounts" v-model="optionalSettings" :autoShow="!!modelValue.fields.seasonalDiscounts?.de?.length">
			<FieldSet id="seasonalDiscounts" class="pt-6">
				<AddButton
					@click="addSeasonalDiscount"
					buttonText="seasonalDiscount"
				/>
				<EditableTable
					class="my-4"
					v-model="modelValue.fields.seasonalDiscounts.de"
					:columns="[ 'startDate', 'endDate', 'reductionPercent' ]"
					typeId="SeasonalDiscount"
					no-data-text="noSeasonalDiscountsSet"
				>
					<template #deleteItem="{ item }">
						<v-icon class="deleteIcon" @click="(action) => handleOptionClick(item.sys.id, 'delete')">mdi-delete</v-icon>
					</template>
				</EditableTable>
			</FieldSet>
		</Optional>

		<!-- TODO: extend field to support an array field that adds elements when pressing enter and shows them as tags -->
		<Optional id="arrivalAndDepartureRestrictions" v-model="optionalSettings" startEnabled
			:autoShow="modelValue.fields.stations?.de?.length > 0" @disable="!modelValue.fields.stations;"
		>
			<FieldSet id="restrictions">
				<Field typeName="AddedValue" fieldName="stations" v-model="modelValue.fields.stations" />
			</FieldSet>
		</Optional>

		<!-- since this is not part of the model we cannot use the Field component for the model driven pattern -->
		<FieldSet v-if="isOutwardJourney && modelValue.fields.stations?.de?.length" id="copyToReturnJourney">
			<v-row no-gutters style="padding-right: 10px;">
				<v-col cols="6" class="d-flex justify-start">
					<p>{{ $t('text.copyToReturnJourneyHelp') }}</p>
				</v-col>
				<v-col cols="6" class="d-flex justify-end">
					<!-- TODO: replace with a button -->
					<v-btn @click="copyStationsForReturnJourney">{{ $t('text.copy') }}</v-btn>
				</v-col>
			</v-row>
		</FieldSet>

		<Field typeName="AddedValue" fieldName="showPrice" v-model="modelValue.fields.showPrice" />
		<Field typeName="AddedValue" fieldName="required" v-model="modelValue.fields.required" />

		<DataDialog v-model="optionalSettings" :title="$t('text.customizeOptions')" ref="optionalSettingsDialog" :useWrap="true">
			<template #content="{ wrap }">
				<!-- TODO: the model is wrong here, we need a separate model per item.
							probably this whole dialog should actually move into the itemHead instead.
				-->
				<OptionalSettings v-model="wrap.model" @update:modelValue="wrap['model'] = $event" :items="optionalSettingsItems" />
			</template>
		</DataDialog>
	</div>
</template>

<script>
import DataDialog from '../../../../components/common/DataDialog.vue'
import Field from '../../../../components/fields/Field.vue'
import FieldSet from '../FieldSet.vue'
import Optional from '../Optional.vue'
import OptionalSettings from '../OptionalSettings.vue'
import eventBus from '@/utils/eventBus.js'
import EditableTable from '../EditableTable.vue'
import AddButton from '../AddButton.vue'

export default {
	components: { DataDialog, Optional, FieldSet, Field, OptionalSettings, EditableTable, AddButton },
	props: {
		modelValue: Object,
	},
	computed: {
		optionalSettingsItems() {
			return [
				{ id: 'arrivalAndDepartureRestrictions' },
				{ id: 'validitySettings' },
				{ id: 'additionalSettings', items: ['showPrice', 'mandatory'] },
			]
		},
		isOutwardJourney() {
			return this.modelValue.fields.transportType.de == 'outward'
		},
	},
	data: () => ({
		optionalSettings: {},
	}),
	methods: {
		// TODO: how can we copy the stations to the return journey when return journey is the same component for a different modelValue? The event bus does not seem to be working for this case
		copyStationsForReturnJourney() {
			eventBus.$emit('copyStationsForReturnJourney', this.modelValue.fields.stations)
		},
		addSeasonalDiscount() {
			const seasonalDiscount = this.seasonalDiscountFactory()
			this.modelValue.fields.seasonalDiscounts.de.push(seasonalDiscount)
		},
		seasonalDiscountFactory() {
			return {
				sys: { id: '' },
				fields: {
					startDate: { de: null },
					endDate: { de: null },
					reductionPercent: { de: 0 },
				},
			}
		},
		handleOptionClick(id, action) {
			if (action === 'delete') {
				const index = this.modelValue.fields.seasonalDiscounts.de.findIndex(item => item.sys.id === id)
				if (index >= 0) {
					this.modelValue.fields.seasonalDiscounts.de.splice(index, 1)
				}
			}
		},
	},
	mounted() {
		console.log(this.modelValue)
	},
	created() {
		eventBus.$on('copyStationsForReturnJourney', (stations) => {
			if (!this.isOutwardJourney) {
				this.modelValue.fields.stations = stations
			}
		})
	},
	beforeUnmount() {
		eventBus.$off('copyStationsForReturnJourney')
	},
}
</script>

<style scoped>
.optional-items > * {
	padding-bottom: 20px;
}
</style>