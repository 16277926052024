<template>
	<div class="Optional" v-show="modelValue[this.id] || autoShow">
		<slot></slot>
	</div>
</template>

<script>
export default {
	emits: [ 'disable' ],
	props: {
		modelValue: Object,
		id: String,
		startEnabled: Boolean,
		autoShow: Boolean,
	},
	watch: {
		autoShow(n) {
			// we automatically switch to enabled if autoShow becomes true
			if (n) this.modelValue[this.id] = true
		},
	},
	mounted() {
		// auto-register in the shared settings model
		this.modelValue[this.id] = (this.modelValue[this.id] ?? this.startEnabled) || this.autoShow

		// we have to watch dynamically because we dont know the id statically
		this.$watch('modelValue.' + this.id + '', (n) => {
			if (!n) {
				this.$emit('disable', this.id)
			}
		})
	},
}
</script>

<style scoped>
</style>