<template>
	<div>
		<LoadingSpinner v-if="loading" style="margin-bottom: 10px;" data-cy="loading" />
<!-- TODO: flag for "hasDayMenu" - or can we automatically detect if the dayMenu slot is given/empty? -->
		<DataCalendar :dates="dates" :calendar="calendar" :limit="limit" v-model:offset="offset" v-model:extended="extended" v-if="calendar.length" ref="dataCalendar">
			<template #dayMenu="{ day }">
				<div class="item" v-if="!dayExpanded[day.date] && day.slots.length > 5" @click="dayExpanded[day.date] = true">
					<v-icon>mdi-unfold-more-horizontal</v-icon> expand
				</div>
				<div class="item" v-if="dayExpanded[day.date] && day.slots.length > 5" @click="dayExpanded[day.date] = false">
					<v-icon>mdi-unfold-less-horizontal</v-icon> collapse
				</div>
			</template>
			<template #day="{ day }">
				{{ day.availabilityInfo }}
				<div>
<!-- TODO: expose the 'selectedDay' as prop? -->
					<div v-for="slot in day.slots" :key="'s' + slot.time" class="slot"
						:class="{
							soldout: slot.free == 0,
							available: slot.free > 0,
							overbooked: slot.free < 0,
							past: day.date < dates.today,
							collapsed: !dayExpanded[day.date] && day.slots.length > 5,
							visited: dayVisited[day.date + slot.time],
						}"
						:title="day.date + ' ' + slot.time + '\n' + slot.free + ' / ' + slot.total + ' free' + (slot.free <= 0 ? '\nSOLD OUT' : '')"
						@click="selectSlot(day, slot)"
					>
						<span class="time">{{ slot.time }}: </span>
						<span class="free">{{ slot.free }}</span>
						<span class="total"> / {{ slot.total }}</span>
					</div>
				</div>
			</template>
		</DataCalendar>
<!-- TODO: timeslots navigation in a sidebar? -->
		<Dialog ref="ordersDialog"
			:showClose="true"
			width="80%"
			height="60%"
			:title="selectedDay?.date + ' ' + selectedTime"
		>
			<template #content>
				<ContingentOrders v-if="selectedDay" ref="contingentOrders"
					:product="product" :date="selectedDay.date" :time="selectedTime"
				/>
			</template>
		</Dialog>
	</div>
</template>

<script lang="ts">
import moment from 'moment'
import Common from '../../../mixins/Common.vue'
import DataCalendar from '../../common/DataCalendar.vue'
import LoadingSpinner from '../../common/LoadingSpinner.vue'
import ContingentOrders from './ContingentOrders.vue'
import Dialog from '../../common/Dialog.vue'

// TODO: respect feature flag

// TODO: loading indicator
// TODO: i generalized to DataCalendar -> also migrate BHCalendar to DataCalendar
// TODO: are removed availabilities shown in this result?
// TODO: why do from and until dates have different time formats?
// TODO: shouldnt the from and until only be 1 minute apart? they are 2h1m apart.. is this a TZ issue?
/*
res = {
	availabilities: [{
		"from": "2023-06-01T09:00:00.000Z",
		"until": "2023-06-01T07:01:00Z",
		"used": 0,
		"free": 5,
		"total": 5,
		"overbookFree": 0,
		"overbookTotal": 0,
		"overbook2Total": 0
	}],
}
*/
export default {
	name: 'ContingentCalendarNew',
	mixins: [ Common ],
	components: { DataCalendar, LoadingSpinner, ContingentOrders, Dialog },
	props: {
		availabilities: Array,
		product: Object,
	},
	data: () => ({
		limit: 7 * 8,
		offset: 0,
		selectedDay: null,
		selectedTime: null,
		dayMenu: false,
		timeMenu: false,
		space: null,
		availabilityDays: null,
		dayExpanded: {},
		dayVisited: {},
		extended: false,
		extendedLimit: 7 * 52,
	}),
	computed: {
		client() {
			return this.$store.state.selectedClient
		},
		dates() {
			// find min and max date
			let minDate = null
			let maxDate = null
			this.availabilities.forEach(ts => {
				const from = ts.fields.validFromDate.de.substring(0, 10)
				if (!minDate || from < minDate) minDate = from
				const to = ts.fields.validToDate.de.substring(0, 10)
				if (!maxDate || to > maxDate) maxDate = to
			})
			const todayDate = moment().startOf('day')
			const today = todayDate.format('YYYY-MM-DD')
			// always include today in the range
			if (today < minDate) minDate = today
			if (today > maxDate) maxDate = today

			const fromDate = moment(minDate).startOf('day')
			const toDate = moment(maxDate).endOf('day')
			const fromMonday = fromDate.subtract((fromDate.day() - 1) % 7 + 7, 'days')
			const toSunday = toDate.add(6 - (toDate.day() - 1) % 7 + 7, 'days')
			const count = toSunday.diff(fromMonday, 'days') + 1
			const offsetToday = Math.floor(todayDate.diff(fromDate, 'days') / this.limit) * this.limit
			return { today, offsetToday, minDate, maxDate, fromDate, toDate, fromMonday, toSunday, count }
		},
		calendar() {
			if (!this.availabilityDays) return []

			// TODO: into separate computed prop since this doesnt change
			const avLookup = {}
			this.availabilityDays.forEach(d => {
				// TODO: TZ?
				const from = moment(d.from)
				const date = d.from.substr(0, 10) //from.format('YYYY-MM-DD')
				if (!avLookup[date]) avLookup[date] = {}
// TODO: slots are offset 2h - probably we may not use moment, but have to cut the time out of the string..
				const time = d.from.substr(11, 5) //from.format('HH:mm')

// TODO: the old comp calculates like this, but how can this be correct?
//       booked: d.total - d.free - d.overbookFree
//          const bookedTotal = (availability.total - availability.free) + (availability.overbookFree * -1)
				avLookup[date][time] = { ...d, time }
			})

			const days = []
			let d = -1
			for (let day = this.dates.fromMonday.clone(); day <= this.dates.toSunday; day = day.add(1, 'days')) {
				d++
				if (d >= this.offset + this.limit && !this.extended) continue
				if (d >= this.offset + this.extendedLimit) continue
				const weekday = day.format('dddd').toLowerCase()
				const date = day.format('YYYY-MM-DD')
				const dateObject = day.toDate()
				const year = day.format('YYYY')
				const inRange = date >= this.dates.minDate && date <= this.dates.maxDate
				let open = false

// TODO: add availability info from contingent svc!
				const slots = Object.values(avLookup[date] ?? {})
// TODO: add the exception infos to the day?

// TODO: show day as open if there is an availability for it?

				days.push({ date, open, /*times,*/ inRange, slots, /*exceptionInfo,*/ dateObject, weekday, year })
			}
			return days
		},
	},
	methods: {
		async load() {
			if (this.loading) return
			this.loading = true
			try {
				// TODO: can we pass a date range?
				const res = await this.$httpGet(`/contingents/${ this.client.fields.clientId.de }/${ this.product.sys.id }`)
				this.space = res.space
				this.availabilityDays = res.availabilities
			}
			catch (error) {
				console.log(error)
				// TODO
				//this.showError(error)
			}
			this.loading = false
		},
		selectSlot(day, slot) {
			this.dayVisited[day.date + slot.time] = true
			this.selectedDay = day
			this.selectedTime = slot.time
			this.$refs.ordersDialog.show = true
			// TODO: hide daymenu of data calendar somehow..
		},
	},
	async mounted() {
		await this.load()
	},
}
</script>

<style scoped>
.slot { padding: 1px 4px; margin-bottom: 2px; background: #eee; border-radius: 4px; position: relative; }
.slot.soldout { background: #eee; }
.slot.available { background: #d4f1d5; }
.slot.oberbooked { background: #f9c2b0; }
.slot.past.soldout { background: #f0f0f0; }
.slot.past.available { background: #e0eae1; }
.slot.past.oberbooked { background: #e3ccc4; }
.slot.collapsed { display: inline-block; margin-right: 2px; }
.slot.collapsed .time { display: none; }
.slot.collapsed .total { display: none; }
.slot:hover { outline: 2px solid #333; cursor: pointer; }
.slot.visited::after { content: ""; position: absolute; top: 2px; right: 2px; width: 3px; height: 3px; border-radius: 3px; background: violet; }
</style>