<template>
	<div class="customizableOptions">
		<!-- TODO: show warning when deselecting something that has autoShow=true -->
		<FieldSet id="customizableOptions">
			<div v-for="itemValue, item of modelValue" :key="item">
				<v-checkbox v-model="modelValue[item]" hide-details density="compact" color="blue">
					<template #label>
						<div class="field-header-text pl-2">
							<p>{{ $t(`text.${item}`) }}</p>
							<p class="field-subtitle">{{ $t(`text.${item}Info`) }}</p>
						</div>
					</template>
				</v-checkbox>
			</div>
		</FieldSet>
	</div>
</template>

<script lang="ts">
import Common from "@/mixins/Common.vue"
import FieldSet from "@/views/applications/packageDesigner/FieldSet.vue"

// TODO: actually it is requested to have a dialog that allows cancel without immediate binding
//       detach with a datadialog?
//       this is potentially more complex than using datadialog since we have watchers on deep attributes..
export default {
	name: 'OptionalSettings',
	components: { FieldSet },
	mixins: [Common],
	emits: ['update:modelValue'],
	props: {
		modelValue: Object,
	},
}
</script>

<style scoped lang="scss">
.flex-header, .child-checkbox {
	border-bottom: 1px solid #DDD;
}

.field-header-text {
	p {
		font-weight: normal;
		color: #000;
		font-size: 15px;
		line-height: 20px;
	}

	.field-subtitle {
		font-size: 12px;
		line-height: 16px;
		color: #999999;
	}
}
</style>

<style>
.customizableOptions {
	.v-expansion-panel-text__wrapper {
		padding-right: 0;
	}

	.v-input__control {
		width: fit-content;
	}
}
</style>
