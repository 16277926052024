<template>
	<div class="BooleanField" :class="{ error }" :data-cy="dataCy">
		<input type="radio" v-model="model" :name="id" :id="id + 'Y'" :value="true" @focus="onFocus" @blur="onBlur" />
		<label :for="id + 'Y'">{{ trueLabel }}</label>
		<input type="radio" v-model="model" :name="id" :id="id + 'N'" :value="false" @focus="onFocus" @blur="onBlur" />
		<label :for="id + 'N'">{{ falseLabel }}</label>
		<span class="clear" v-if="field.required != true && modelValue !== undefined && modelValue !== null" @click="$emit('update:modelValue', null)">Clear</span>
	</div>
</template>

<script>
import { field } from './FieldMixin.js'

// TODO: also create a Switch control using mys-switch

export default {
	name: 'BooleanField',
	mixins: [ field ],
	props: {
		modelValue: Boolean,
		dataCy: String,
	},
	data: () => ({
		id: 'RG-' + Math.random(),
		model: null,
	}),
	computed: {
		trueLabel() {
			return this.field.control?.settings?.trueLabel ?? 'Yes'
		},
		falseLabel() {
			return this.field.control?.settings?.falseLabel ?? 'No'
		},
	},
	watch: {
		// TODO: on initial load this does not fire!
		value(n) {
			this.model = n
			this.onErrors([
				this.validateRequired(),
			])
		},
	},
	mounted() {
		this.onErrors([
			this.validateRequired(),
		])
	},
}
</script>

<style scoped>
label { padding-left: 5px; margin-right: 15px; }
.clear { color: rgb(0, 89, 200); padding-left: 20px; cursor: pointer; }
</style>