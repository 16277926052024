<template>
	<Table
		:headers="headers"
		:items="items"
		:sortBy="sortBy"
		@update:sortBy="sortBy = $event"
		:loading="loading"
		show-expand
		show-select
		v-model:expanded="expanded"
		v-model:selected="selectedModel"
		@update:selected="$emit('update:selected', $event)"
		:total="total"
		:limit="limit"
		:offset="offset"
		@update:limit="$emit('update:limit', $event)"
		@update:offset="$emit('update:offset', $event)"
		:noDataText="$t('text.noProductsAvailable')"
	>
		<template #[`item.product_name`]="{ item }">
			<v-tooltip location="bottom">
				<template #activator="{ props }">
					<div class="service" v-bind="props">
						<p class="sp-name text-truncate">{{ item.product_service_provider_name }}</p>
						<p class="p-name text-truncate" data-cy="product-name">{{ getProductName(item.product_name) }}</p>
					</div>
				</template>
				<span class="tooltip">{{ getProductName(item.product_name) }}</span>
			</v-tooltip>
		</template>

		<template #[`item.product_sku`]="{ item }">
			<v-tooltip location="bottom">
				<template #activator="{ props }">
					<p class="sku text-truncate" v-bind="props" data-cy="product-sku">{{ item.product_sku }}</p>
				</template>
				<span class="tooltip">{{ item.product_sku }}</span>
			</v-tooltip>
		</template>

		<template #[`item.activities`]="{ item }">
			<div>
				<div class="main-category" v-if="item.activities?.length">
					<div class="category-chip" v-for="{ activity_id, activity_reference, activity_name } of item.activities"
						:key="`${activity_id}_${activity_reference}`"
					>
						<p>{{ activity_name }}</p>
					</div>
				</div>
			</div>
		</template>

		<template #[`item.product_price`]="{ item }">
			<!-- TODO: correct currency symbol -->
			<p class="price" v-if="item.product_price !== null" data-cy="product-price">CHF {{ item.product_price?.toFixed(2) }}</p>
		</template>

		<template #[`item.product_websites`]="{ item }">
			<div class="sales-channels" data-cy="product-channels">
				<div v-if="item.product_websites?.length">
					<p>
						<span>
							{{ item.product_websites.slice(0, 2)
								.map(({ product_website_name }) => product_website_name).join(', ') }}
						</span>
						<span v-if="item.product_websites.slice(2).length">
							, +{{ item.product_websites.slice(2).length }} {{ $t('text.more') }}
						</span>
					</p>
				</div>
			</div>
		</template>

		<template #[`item.product_status`]="{ item }">
			<v-tooltip location="bottom">
				<template #activator="{ props }">
					<v-icon v-if="item.product_status === 'activated'" color="#64c823" data-cy="product-active"
						v-bind="props">mdi-check-circle</v-icon>
					<v-icon v-if="item.product_status === 'deactivated'" color="red" v-bind="props" data-cy="product-inactive">mdi-close</v-icon>
				</template>
				<span class="tooltip">{{ $t(`text.${item.product_status}`) }}</span>
			</v-tooltip>
		</template>

		<!-- Expanded Row -->
		<template #expanded-row="{ item }">
			<!-- General -->
			<p class="title mb-2">{{ $t('text.packageDesignerGeneral') }}:</p>
			<div class="expanded-row">
				<div class="general-row">
					<!-- SKU -->
					<div class="row-item">
						<p>{{ $t('text.articleNumber') }}/ SKU:</p>
						<p>{{ item.product_sku }}</p>
					</div>
					<!-- Title -->
					<div class="row-item">
						<p>{{ $t('text.title') }}:</p>
						<p>{{ getProductName(item.product_name) }}</p>
					</div>
					<!-- Service Provider -->
					<div class="row-item">
						<p>{{ $t('text.serviceProvider') }}:</p>
						<p>{{ item.product_service_provider_name }}</p>
					</div>
					<!-- Type -->
					<div class="row-item">
						<p>{{ $t('text.type') }}:</p>
						<p>{{ item.product_type.charAt(0).toUpperCase() + item.product_type.slice(1) }}</p>
					</div>
				</div>

				<div class="flex-2">
					<div class="general-row-second">
						<!-- Created -->
						<div class="row-item">
							<p>{{ $t('text.created') }}:</p>
							<p>{{ formatDate(item.product_created_at) }}</p>
						</div>
						<!-- Last Updated -->
						<div class="row-item">
							<p>{{ $t('text.lastUpdated') }}:</p>
							<p>{{ formatDate(item.product_updated_at) }}</p>
						</div>
					</div>
				</div>
			</div>
			<!-- TODO Categories inluding subcategories will be added soon -->
			<!-- Categories -->
			<!-- <div class="expanded-row mt-6">
				<div class="categories-row">
					<p class="title mb-4">{{ $t('text.categories') }}:</p>
					<CategoriesTable :items="categories" />
				</div>
				<v-spacer class="flex-2" />
			</div> -->
		</template>
	</Table>
</template>

<script>
import Common from '@/mixins/Common.vue'
import Table from '../../../../components/common/tables/Table.vue'
import ApprovalStatus from '../../../../components/common/ApprovalStatus.vue'
import Status from '../../../../components/common/tables/Status.vue'
import IncludedProductsTable from './IncludedProductsTable.vue'
import CategoriesTable from './CategoriesTable.vue'

export default {
	name: 'ProductsTable',
	components: { Table, ApprovalStatus, Status, IncludedProductsTable, CategoriesTable },
	emits: [ 'update:selected' ],
	mixins: [ Common ],
	props: {
		items: { type: Array, default: () => [] },
		total: Number,
		limit: Number,
		offset: Number,
		selected: Array,
	},
	data: () => ({
		sortBy: [],
		expanded: [],
		selectedModel: [],
	}),
	computed: {
		headers() {
			return [
				{ title: this.$t('text.service'), key: 'product_name', value: 'product_name', sortable: false },
				{ title: `${this.$t('text.articleNumber')} / SKU`, key: 'product_sku', value: 'product_sku', sortable: false },
				// { title: this.$t('text.productCategory'), key: 'activities', value: 'activities', sortable: false },
				{ title: this.$t('text.price'), key: 'product_price', value: 'product_price', sortable: false },
				{ title: this.$t('text.salesChannels'), key: 'product_websites', value: 'product_websites', sortable: false },
				{ title: this.$t('text.status'), key: 'product_status', value: 'product_status', align: 'center', sortable: false },
			]
		},
		categories() {
			const result = []

			this.items.forEach(({ id, mainCategory, secondaryCategory }) => {
				mainCategory?.forEach(category => {
					result.push({
						id,
						mainCategory: category,
						subCategory: secondaryCategory?.[category],
					})
				})
			})

			return result
		},
	},
	watch: {
		selectedModel(n) { this.$emit('update:selected', n) },
		selected(n) { this.selectedModel = n },
	},
	methods: {
		getProductName(name) {
			return name.find(({ locale_code }) => locale_code?.indexOf(this.serviceLocale) > -1 || locale_code?.indexOf('de') > -1)?.value
		},
	},
	mounted() {
		this.selectedModel = this.selected
	},
}
</script>

<style scoped lang="scss">
.service {
	max-width: 300px;

	.p-name {
		font-size: 15px;
		color: #000;
	}

	.sp-name {
		font-size: 12px;
		color: #999;
	}
}

.sku,
.price { font-size: 15px; color: #000; white-space: nowrap; }
.sku { max-width: calc(90vw - 720px); min-width: 100px; }

.main-category {
	display: flex;
	flex-wrap: wrap;
	gap: 5px;

	.category-chip {
		background-color: #DADADA;
		padding: 2px 5px;
		border-radius: 5px;

		p {
			font-size: 13px;
			color: #000;
			white-space: nowrap;
		}
	}
}

.sales-channels {
	p {
		font-size: 15px;
		color: #000;
	}
}

.expanded-row {
	display: flex;
	gap: 100px;

	.general-row {
		display: flex;
		gap: 5px;
		flex-direction: column;
		flex: 1;
	}

	.general-row-second {
		display: flex;
		gap: 5px;
		flex-direction: column;
		flex: 1;
		width: 50%;
	}

	.marketplace-row {
		display: flex;
		flex-direction: column;
		flex: 1;
	}

	.product-row {
		display: flex;
		flex-direction: column;
		flex: 2;
	}

	.categories-row {
		flex: 1;
	}

	.sales-channels-headers {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid grey;
	}

	.row-item {
		display: flex;
		justify-content: space-between;
	}

	.flex-2 {
		flex: 2;
	}
}
</style>