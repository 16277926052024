<template>
	<div class="ContactAddress" v-if="contactAddress">
		<v-label>{{ title }} <span class="error-text">({{ $t('text.required') }})</span></v-label>
		<TextField :title="$t('text.placeHolderAddress')"
			:error-messages="streetAddressErrors"
			@input="validateStreetAddress"
			@blur="autogeocode()"
			@change="$emit('change')"
			v-model="contactAddress.fields.streetAddress.de"
		/>
		<div style="display: flex; gap: 15px;">
			<TextField :title="$t('text.placeHolderPostCode')"
				style="flex-grow: 1;"
				:error-messages="zipCodeErrors"
				@input="validateZipCode"
				@blur="autogeocode()"
				@change="$emit('change')"
				v-model="contactAddress.fields.zipCode.de"
			/>
			<TextField :title="$t('text.placeHolderCityTown')"
				style="flex-grow: 1;"
				:error-messages="cityErrors"
				@input="validateCity"
				@blur="autogeocode()"
				@change="$emit('change')"
				v-model="contactAddress.fields.city.de"
			/>
		</div>
		<FieldWrap :title="$t('text.placeHolderCountry')">
			<!-- TODO: why is country not bound to .de? -->
			<v-select variant="outlined" density="compact" return-object
				style="margin-top: 3px;"
				hide-details
				:placeholder="$t('text.placeHolderCountry')"
				:error-messages="countryErrors"
				:items="countryNames"
				:item-title="item => item?.[selectedLocale] || $t('text.placeHolderCountry')"
				@update:modal-value="validateCountry"
				@change="$emit('change')"
				v-model="contactAddress.fields.country"
			/>
		</FieldWrap>
		<LocationFieldOld ref="location"
			:value="location"
			:address="contactAddress"
			@change="$emit('change')"
		/>
	</div>
</template>

<script>
import Common from '@/mixins/Common.vue'
import TextField from './TextField.vue'
import FieldWrap from './FieldWrap.vue'
import LocationFieldOld from './LocationFieldOld.vue'
import { ekLocation } from './LocationFieldOld.vue'

// ATT: "ek" == "ErrorKey"

export function ekContactAddress(value) {
	return ekStreetAddress(value.fields.streetAddress.de)
		|| ekZipCode(value.fields.zipCode.de)
		|| ekCity(value.fields.city.de)
		|| ekCountry(value.fields.country)
}

function ekStreetAddress(value) {
	if (value?.length == 0) return 'text.businessAddressRequiredError'
}

function ekZipCode(value) {
	if (value?.length == 0) return 'text.businessAddressRequiredError'
}

function ekCity(value) {
	if (value?.length == 0) return 'text.businessAddressRequiredError'
}

function ekCountry(value) {
	console.log('ekCountry', value?.de)
	if (!value || Object.keys(value).length == 0 || value?.de?.length === 0) return 'text.businessAddressRequiredError'
}

export default {
	name: 'ContactAddress',
	mixins: [ Common ],
	components: { TextField, FieldWrap, LocationFieldOld },
	props: {
		title: String,
		contactAddress: Object,
		location: Object,
	},
	data: () => ({
		countries: [],
		streetAddressErrors: [],
		postalCodeErrors: [],
		cityErrors: [],
		countryErrors: [],
		zipCodeErrors: [],
	}),
	computed: {
		countryNames() {
			return this.countries.map(item => item.name)
		},
	},
	methods: {
		async autogeocode() {
			await this.$refs.location.autogeocode()
		},
		getMessages(key) {
			if (!key) return []
			return [ this.$t[key] ]
		},
		validateAllFields() {
			const locationError = ekLocation(this.location)
			return ekContactAddress(this.contactAddress)
		},
		validateStreetAddress() {
			this.streetAddressErrors = this.getMessages(ekStreetAddress(this.contactAddress?.fields?.streetAddress?.de))
		},
		validateZipCode() {
			this.zipCodeErrors = this.getMessages(ekZipCode(this.contactAddress?.fields?.zipCode?.de))
		},
		validateCity() {
			// HACK: i dont know why, but initially city is validated, but country not, so i force it here
			this.validateCountry()
			this.cityErrors = this.getMessages(ekCity(this.contactAddress?.fields?.city?.de))
		},
		validateCountry() {
			this.countryErrors = this.getMessages(ekCountry(this.contactAddress?.fields?.country))
		},
	},
	mounted() {
		this.countries = this.getCountries()
	},
}
</script>

<style scoped>
.ContactAddress { }
</style>