<template>
	<div style="width: 100%;" ref="root">
		<v-data-table fixed-header
			:headers="ordersHeaders"
			:items="orderItems"
			item-key="from"
			class="elevation-0"
			:items-per-page="1000"
			:item-value="item => item"
		>
			<template #item="{ item }">
				<tr>
					<td>{{ item.order_id }}</td>
					<td>{{ formatDateTime(item.order_date) }}</td>
					<td>{{ item.client_name }}</td>
					<td>{{ item.customer.firstname }} {{ item.customer.lastname }}</td>
					<td></td>
				</tr>
			</template>
			<template #bottom></template>
		</v-data-table>
		<p class="helpText" v-html="$t('text.cmOrdersHelp')" />
	</div>
</template>

<script>
export default {
	props: {
		product: Object,
		date: String,
		time: String,
	},
	data: () => ({
		orderItems: [],
	}),
	computed: {
		ordersHeaders() {
			return [
				{ title: 'ID', key: 'orderId', width: '15%', sortable: false },
				{ title: this.$t('text.orderDate') , key: 'orderDate', width: '15%', sortable: false },
				{ title: this.$t('text.marketplace') , key: 'marketplace', width: '15%', sortable: false },
				{ title: this.$t('text.customerName') , key: 'customerName', width: '15%', sortable: false },
				{ title: '', key: 'viewOrder', width: '5%', sortable: false, align: 'center' },
			]
		},
	},
	methods: {
		// TODO: should we actually use this endpoint to get Reservations instead of orders at first?
		//       http://contingentservice.stage.alturos.com/api/contingent/reservations?from=2023-01-01T00%3A00%3A00Z&spaceId=592584&until=2023-11-01T23%3A00%3A00Z
		async load() {
			// TODO: indicator
			try {
				//this.time = `${ time.split("T")[1].substring(0,5) }:00`

				let sku = ''
				if (this.product?.fields?.clientAssignments?.de?.length) {
					for (const clientAssignment of this.product.fields.clientAssignments.de) {
						if (clientAssignment?.fields?.sku?.de) {
							sku = clientAssignment.fields.sku.de
							break
						}
					}
				}

				const res = await this.$httpGet(`/order-items?sku=${ sku }&eventDate=${ this.date }&timeslot=${ this.time }:00`)
				this.orderItems = res.orderItems
			}
			catch (error) {
				console.error(error)
				// TODO
				//this.showError(error)
			}
		},
	},
	mounted() {
		this.load()
	},
}
</script>