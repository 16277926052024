<template>
	<FilterMenu @clear="clearFilter" @apply="applyFilter">
		<!-- MYS-5227: only product type "OTHER" should see the attribute set filter -->
		<FilterFieldWrap v-show="productType == 'OTHER'" dataCy="categoryFilter">
			<span>{{ $t('text.productCategory') }}</span>
			<v-combobox :placeholder="$t('text.allLabel')" variant="outlined" density="compact"
				v-model="selectedAttributeSets" :items="attributeSetOptions"
				multiple chips hide-details closable-chips :return-object="false"
			>
			</v-combobox>
		</FilterFieldWrap>

		<FilterFieldWrap dataCy="priceRangeFilter">
			<span>{{ $t('text.priceRange') }}</span>
			<div class="d-flex ga-3 mt-2">
				<v-text-field v-model.trim="selectedPriceRange.from" :label="$t('text.from')" variant="outlined"
					density="compact" hide-details />
				<v-text-field v-model.trim="selectedPriceRange.to" :label="$t('text.to')" variant="outlined"
					density="compact" hide-details />
			</div>
		</FilterFieldWrap>

		<FilterFieldWrap dataCy="salesChannelsFilter">
			<span>{{ $t('text.salesChannels') }}</span>
			<v-select :placeholder="$t('text.allLabel')" variant="outlined" density="compact"
				v-model="selectedSalesChannels" :items="salesChannelsOptions" multiple chips hide-details>
				<template #selection="{ item }">
					<v-chip :color="item.color" dark>{{ $t('text.' + item.id) }}</v-chip>
				</template>
			</v-select>
		</FilterFieldWrap>

		<FilterFieldWrap dataCy="statusFilter">
			<span>{{ $t('text.status') }}</span>
			<v-select :placeholder="$t('text.allLabel')" variant="outlined" density="compact" v-model="selectedStatuses"
				:items="statusOptions" multiple chips hide-details>
				<template #selection="{ item }">
					<v-chip :color="item.color" dark>{{ $t('text.' + item.id) }}</v-chip>
				</template>
			</v-select>
		</FilterFieldWrap>
	</FilterMenu>
</template>

<script lang="ts">
import Common from '@/mixins/Common.vue'
import FilterMenu from '@/components/common/FilterMenu.vue'
import FilterFieldWrap from '@/components/common/FilterFieldWrap.vue'

export default {
	name: 'ProductFilters',
	components: { FilterMenu, FilterFieldWrap },
	mixins: [Common],
	props: {
		onApplyFilter: Function,
		productType: String,
	},
	data() {
		return {
			loading: false,
			selectedAttributeSets: [],
			selectedPriceRange: { from: null, to: null },
			selectedSalesChannels: [],
			selectedStatuses: [],
			attributeSetOptions: [],
			salesChannelsOptions: [],
		}
	},
	computed: {
		statusOptions() {
			return [
				{ title: this.$t('text.activated'), value: 'activated', color: 'green' },
				{ title: this.$t('text.deactivated'), value: 'deactivated', color: 'red' },
			]
		},
		clientId() {
			return this.$store.state.selectedClient.sys.id
		},
	},
	methods: {
		showLoader(loading = false) {
			this.loading = loading
		},
		async getAttributeSetOptions() {
			try {
				this.showLoader(true)
				const { attributeSets } = await this.$httpGet(`/packageTravel/attributeSets?clientId=${this.clientId}`)
				console.log('attributeSets', attributeSets)
				this.attributeSetOptions = attributeSets.map(({ attribute_set_id, attribute_set_name }) => ({ title: attribute_set_name, value: attribute_set_id, id: attribute_set_id }))
			} catch (error: any) {
				this.errorTitle = this.$t('text.ERROR')
				this.errorDetail = error.response ? error.response.error : error
			} finally {
				this.showLoader()
			}
		},
		async getProductSalesChannelsOptions() {
			try {
				this.showLoader(true)
				const { salesChannels } = await this.$httpGet(`/packageTravel/getSalesChannels?clientId=${this.clientId}`)
				this.salesChannelsOptions = salesChannels.map(({ name, id }) => ({ title: name, value: name, id }))
			} catch (error: any) {
				this.errorTitle = this.$t('text.ERROR')
				this.errorDetail = error.response ? error.response.error : error
			} finally {
				this.showLoader()
			}
		},
		clearFilter() {
			this.selectedAttributeSets = []
			this.selectedPriceRange = { from: null, to: null }
			this.selectedSalesChannels = []
			this.selectedStatuses = []

			this.applyFilter()
		},
		applyFilter() {
			this.onApplyFilter?.({
				categories: this.selectedAttributeSets,
				salesChannels: this.selectedSalesChannels,
				status: this.selectedStatuses,
				priceRangeFrom: this.selectedPriceRange.from,
				priceRangeTill: this.selectedPriceRange.to,
			})
		},
	},
	async mounted() {
		this.getAttributeSetOptions()
		this.getProductSalesChannelsOptions()
	},
}
</script>
