<template>
	<ProfileColorCalendar v-model="profiles">
		<template #header>
			<v-btn @click="addProfile()">Add</v-btn>
		</template>
		<template #item="{ profile }">
			<div style="display: flex; flex-direction: column; gap: 10px; align-items: top;">
				<table v-if="profile.prices">
					<tr>
						<th></th>
						<th>Adult</th>
						<th>Child</th>
						<th>Senior</th>
					</tr>
					<tr>
						<th>1 day</th>
						<td><input type="number" v-model="profile.prices.adult_1" /></td>
						<td><input type="number" v-model="profile.prices.child_1" /></td>
						<td><input type="number" v-model="profile.prices.senior_1" /></td>
					</tr>
					<tr>
						<th>2 day</th>
						<td><input type="number" v-model="profile.prices.adult_2" /></td>
						<td><input type="number" v-model="profile.prices.child_2" /></td>
						<td><input type="number" v-model="profile.prices.senior_2" /></td>
					</tr>
				</table>
			</div>
		</template>
	</ProfileColorCalendar>
</template>

<script>
import ProfileColorCalendar from './ProfileColorCalendar.vue'

export default {
	components: { ProfileColorCalendar },
	props: {
		modelValue: Object,
		salesChannels: Array,
		products: Array,
	},
	data: () => ({
		profiles: [
			{ id: 0, color: null, name: 'default', isRemove: true },
			{ id: 1, color: '#feefc9', name: 'summer', prices: {} },
			{ id: 2, color: '#c9f7fe', name: 'winter', prices: {} },
			{ id: 3, color: '#f2dbf6', name: 'holiday', prices: {} },
		],
	}),
	methods: {
		addProfile() {
			const maxId = Math.max(...this.profiles.map(p => p.id))
			this.profiles.push({
				id: maxId + 1,
				color: '#ffffff',
				name: 'new',
				prices: {},
			})
		},
	},
}
</script>

<style scoped>
input { border: 1px solid silver; width: 50px; text-align: center; }
</style>
