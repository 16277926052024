<template>
	<Disclosure :title="$t('text.businessHours')" :error="sectionMessage.error" :message="sectionMessage.message" :lock="!userMayEditFields" data-cy="businessInfo" ref="SECTION_businessInfo">
		<p class="subTitle" v-if="app === 'BusinessProfile'" v-html="$t('text.businessProfileInfoDesc')"/>
		<p class="subTitle" v-if="app === 'ServiceDesigner'" v-html="$t('text.serviceDesignerInfoDesc')"/>

		<div class="field left-border">
			<div v-if="app === 'BusinessProfile'">
				<v-label>{{ $t('text.averageDurationOfStayInMinutes') }}</v-label>
				<v-text-field
					variant="outlined" density="compact" hide-details v-mask="'####'"
					v-model="averageDurationOfStayInMinutesLocal"
					style="width: 15%;"
					@update:modelValue="$emit('average-duration-changed', averageDurationOfStayInMinutesLocal)"
				/>
				<p class="helpText" v-html="$t('text.averageDurationOfStayInMinutesDesc')" style="margin-left:0px"/>
			</div>
			<p class="subTitle" v-html="$t('text.businessHours')" style="margin-top: 10px;" />
			<p class="helpText" v-if="!showCopyOption" v-html="$t('text.businessHoursHelp')"/>
			<p class="helpText" v-if="showCopyOption" v-html="$t('text.businessHoursServiceHelp')"/>

			<div v-if="showCopyOption">
				<v-radio-group v-model="copyOption" col hide-details>
					<v-radio style="height:48px" :label="$t('text.noBusinessHours')" value="NONE"></v-radio>
					<hr/>
					<v-radio style="height:48px" :label="$t('text.copyBusinessHours')" value="COPY" ></v-radio>
					<hr/>
					<v-radio style="height:48px" :label="$t('text.newBusinessHours')" value="NEW"></v-radio>
				</v-radio-group>
			</div>

			<div v-if="!showCopyOption"
				style="background: #eee; border-radius: 5px; padding: 10px; padding-right: 30px; margin: 10px 0; display: flex; gap: 10px;"
			>
				<div style="flex-grow: 1;">
					<p class="subTitle" v-html="businessHoursText" />
					<p v-html="businessHoursHelpText" />
				</div>
				<mys-switch
					v-model="haveBusinessHours"
					style="flex-shrink: 0;"
				/>
			</div>
		</div>

		<div v-if="copyOption == 'NEW' && (haveBusinessHours || showCopyOption)">
			<p class="subTitle" v-html="$t('text.regularHours')" />
			<fieldset style="border: none;">
				<div v-for="(timeSpan, ts) of businessHours" :key="timeSpan.sys.id"
					class="timeSpan" :class="{ error: validationErrors[ts]?.length }"
				>
					<button class="removeRow" v-if="businessHours.length > 1" @click="removeBusinessHours(ts, false)">
						<v-icon color="white">mdi-delete</v-icon>
					</button>
					<div style="border-radius: 5px; padding: 10px; display: flex; gap: 10px; align-items: center; font-weight: bold;">
						{{ formatDate(timeSpan.fields.validFromDate.de) }} - {{ formatDate(timeSpan.fields.validToDate.de) }}
						<button @click="edit(ts)"><v-icon>mdi-pencil</v-icon></button>
					</div>
					<BusinessHoursTimeTable :timeSpan="timeSpan" style="margin-left: 10px;" />
					<div v-for="error, e of validationErrors[ts]" :key="e" class="error">
						{{ error.text }}
					</div>
				</div>
				{{ selectedTimes }}
				<v-btn class="gradientButton" elevation="0" @click="addBusinessHours()" style="margin-bottom: 15px;"><v-icon>mdi-plus</v-icon> {{ $t('text.businessHoursAddTimespan') }}</v-btn><!-- {{ $t('text.addAnotherTimeFrame') }} -->
			</fieldset>
			<BusinessHoursCalendar :timeSpans="businessHours" :exceptions="exceptions" />
		</div>

		<DataDialog ref="timespanDialog"
			class="timespanDialog"
			v-model="editingTimespan"
			:title="$t('text.businessHours')"
			height="500px"
			width="800px"
			@update:modelValue="editComplete"
			:validator="model => model.fields.validFromDate.de >= model.fields.validToDate.de ? $t('text.fromDateAfterToDateError') : null"
		>
			<template #content="{ model }">
				<div style="flex-grow: 1;">
					<div style="display: flex; gap: 10px;">
						<div style="width: 100%; flex-grow: 1;">{{ $t('text.dateFrom') }}</div>
						<div style="width: 100%; flex-grow: 1;">{{ $t('text.dateTo') }}</div>
					</div>
					<div style="display: flex; gap: 10px;">
						<IsoDatePicker v-if="model" v-model="model.fields.validFromDate.de" tz="Europe/Vienna" style="width: 100%; flex-grow: 1;" />
						<IsoDatePicker v-if="model" v-model="model.fields.validToDate.de" tz="Europe/Vienna" style="width: 100%; flex-grow: 1;" />
					</div>
				</div>
			</template>
		</DataDialog>
	</Disclosure>
</template>

<script>
// DESIGN: new design for business hours: https://overflow.io/s/X22BN13G/?node=d3fe2358
//         i see some usability problems with the designs, so i did not implement them

// TODO: prevent overlap (for now, actually JAMES should implement the overlap logic)
// TODO: copy exceptions into other timespans where they fit (plus have all in the first ts) 

// TODO: there are quite a few event interactions with the container - these should be removed,
//       "copy" should be fully handled here.

// TODO: binding: we should directly bind to the product model without DDE
// TODO: how can we support the change monitor that then sets the dirty flags in the requests?

// TODO: talk to Roni: is this gymnastics really necessary?
//       could we not just store all the exceptions into the first span?
//       talked to Bence + Roni: currently out-of-order exceptions and overlaps are unsupported in JAMES
//       Bence estimates 1-2d work to do this.
//       -> we will do a 2 phase plan
// PHASE 1:
// - we prevent overlaps
// - we will store all exceptions in the first timespan AND copy them to the "fitting" timespans
// TODO: validation: prevent timespan overlaps
// TODO: more validation?
// PHASE 2 (requires JAMES to support this):
// - we will store all exceptions in the first timespan ONLY
// - we will allow overlaps

import Common from '@/mixins/Common.vue'
import Disclosure from '@/components/common/Disclosure.vue'
import moment from 'moment'
import BusinessHoursCalendar from './BusinessHoursCalendar.vue'
import DataDialog from '../common/DataDialog.vue'
import IsoDatePicker from './IsoDatePicker.vue'
import BusinessHoursTimeTable from './BusinessHoursTimeTable.vue'
import isEqual from 'lodash/isEqual'
import { DATE_FORMATS } from '@/constants'

export default {
	name: 'BusinessHoursNew',
	mixins: [ Common ],
	components: { Disclosure, BusinessHoursCalendar, DataDialog, IsoDatePicker, BusinessHoursTimeTable },
	props: {
		averageDurationOfStayInMinutes: Number,
		showCopyOption: Boolean,
		copyBusinessHours: Boolean,
		businessHours: Array,
		// TODO: use injection instead
		app: String,
		updateModel: Boolean
	},
	data: () => ({
		copyOption: 'COPY', // NONE | COPY | NEW
		selectedTimes: null,
		sectionMessage: {
			error: false,
			message: ''
		},
		haveBusinessHours: true,
		averageDurationOfStayInMinutesLocal: 0,
		// we handle exceptions globally, even though the data model has them inside timespans.
		exceptions: null,
		originalData: null,
		editingTimespan: null,
		editingTimespanIndex: null,
	}),
	computed: {
		businessHoursText() {
			if (this.app === 'BusinessProfile') return this.$t('text.haveBusinessHoursSP')
			else if (this.app === 'ServiceDetail') return this.$t('text.haveBusinessHoursService')
			return ''
		},
		businessHoursHelpText() {
			if (this.app === 'BusinessProfile') return this.$t('text.haveBusinessHoursSPHelp')
			if (this.app === 'ServiceDetail') return this.$t('text.haveBusinessHoursServiceHelp')
			return ''
		},
		validationErrors() {
			const r = {}
			for (const [ts1, timeSpan1] of this.businessHours.entries()) {
				r[ts1] = []
				for (const [ts2, timeSpan2] of this.businessHours.entries()) {
					if (!timeSpan1?.fields || !timeSpan2?.fields) continue
					if (timeSpan1 == timeSpan2) continue
					if (timeSpan1.fields.validFromDate.de > timeSpan2.fields.validToDate.de
						|| timeSpan1.fields.validToDate.de < timeSpan2.fields.validFromDate.de) continue
					r[ts1].push({ text: this.$t('text.businessHoursOverlapError'), field: 'validFromDate', otherObject: timeSpan2, otherIndex: ts2 })
					break
				}
			}
			return r
		},
	},
	watch: {
		copyOption(v) {
			if (v == 'COPY') {
				this.$emit('copy-business-hours')
			}
			else if (v == 'NEW') {
				// TODO: should we not rather just reset to the original data?
				this.$emit('new-business-hours')
			}
			else {
				this.$emit('new-business-hours')
				if (this.businessHours) while (this.businessHours.length) this.businessHours.pop()
				if (this.exceptions) while (this.exceptions.length) this.exceptions.pop()
			}
		},
		exceptions: {
			deep: true,
			handler() {
				// add all exceptions to the first timeSpan
				if (!this.businessHours[0]) return
				// make sure, all timeSpans are wellformed
				for (const timeSpan of this.businessHours) {
					// ATT: we always reset the exceptions here. this also handles the NPE case
					timeSpan.fields.exceptions = { de: [] }
				}
				// TODO: group together same exceptions (same closed + same times, multiple dates)
				for (const exception of this.exceptions) {
					const ex = { dates: [ exception.date ], closed: exception.closed, times: exception.times }
					// find the right timeSpan and add the exception there
					let found = false
					for (const timeSpan of this.businessHours) {
						if (exception.date < timeSpan.fields.validFromDate.de
							|| exception.date > timeSpan.fields.validToDate.de) continue
						timeSpan.fields.exceptions.de.push(ex)
						found = true
					}
					// if we cant find the right one, we add it to the first one
					if (!found) {
						this.businessHours[0].fields.exceptions.de.push(ex)
					}
				}
			},
		},
	},
	methods: {
		formatDate(date) {
			if (date !== "") {
				return moment(String(date.split("T")[0])).format(DATE_FORMATS.DATE)
			} else {
				return date
			}
		},
		addBusinessHours() {
			this.editingTimespanIndex = -1
			this.editingTimespan = this.getNewBusinessHours()
			this.$refs.timespanDialog.open()
		},
		edit(tsIndex) {
			this.editingTimespanIndex = tsIndex
			this.editingTimespan = this.businessHours[tsIndex]
			this.$refs.timespanDialog.open()
		},
		// TODO: should we rather use a computed field with getter and setter?
		editComplete(timespan) {
			if (this.editingTimespanIndex >= 0) {
				this.businessHours[this.editingTimespanIndex] = timespan
			}
			else {
				this.businessHours.push(timespan)
			}
			this.editingTimespanIndex = null
			this.editingTimespan = null
		},
		removeBusinessHours(index, isException) {
			this.businessHours.splice(index, 1)
		},
		getNewBusinessHours() {
			const counter = Math.random()

			let today = moment(new Date()).format('YYYY-MM-DD')
			let validToDate = moment(today).add(1, 'month').format('YYYY-MM-DD')

			return {
				sys: { id: 'id_bh_' + counter },
				fields: {
					validFromDate: { de: today },
					validToDate: { de: validToDate },
					isException: {de: false},
					businessTimes: {
						de: {
							monday:    { times: [{ openTime: '', closeTime: '' }] },
							tuesday:   { times: [{ openTime: '', closeTime: '' }] },
							wednesday: { times: [{ openTime: '', closeTime: '' }] },
							thursday:  { times: [{ openTime: '', closeTime: '' }] },
							friday:    { times: [{ openTime: '', closeTime: '' }] },
							saturday:  { times: [{ openTime: '', closeTime: '' }] },
							sunday:    { times: [{ openTime: '', closeTime: '' }] },
							holidays:  { times: [{ openTime: '', closeTime: '' }] },
						},
					},
				},
			}	
		},
		sendData() {
			// TODO: write back exceptions into timespans - or do this on change of exceptions?

			return {
				businessHours: { de: this.businessHours },
				changed: !isEqual(this.businessHours, this.originalData),
			}
		},
		validateAllFields() {
			return Object.values(this.validationErrors).every(e => e.length == 0)
		},
	},
	mounted() {
		this.originalData = JSON.parse(JSON.stringify(this.businessHours))
		// TODO: maybe this complexity should rather be in the calendar?
		//       because there we also get all timespans and here we actually dont deal with exceptions..
		// TODO: move this to a func, also call it when the business hours are changed
		// for each timespan: collect exceptions into a single array
		const dl = this.locales[0].code
		const exceptions = {}
		for (const timespan of this.businessHours) {
			// TODO: why are excepitons defined in multiple locales? (see GT3 product)
			for (const exception of timespan.fields.exceptions?.[dl] ?? []) {
				// TODO: disadvantage of splitting: we have way more objects (and user actions)
				//       if the exception spans are long.
				//       - storage concerns could be addressed by merging
				//       - user actions: we could allow to
				//         - "copy" to the whole week
				//         - "brush" a settings with a single click for each day
				//         - "drag" over a number of days
				// we resolve multi-day-exceptions into single-days
				for (const date of exception.dates ?? [ exception.date ]) {
					exceptions[date] = {
						date,
						closed: exception.closed,
						times: [ ...exception.times ],
					}
				}
			}
		}
		this.exceptions = Object.values(exceptions)
		// TODO: on change of the calendar: distribute the exceptions back into the timespans
		//       should we group same (closed + times / JSON equal) exceptions into one exception object?

		this.copyOption = this.copyBusinessHours ? 'COPY' : this.businessHours?.length == 0 ? 'NONE' : 'NEW'
		if (this.app === 'BusinessProfile') this.copyOption = 'NEW'
		this.haveBusinessHours = this.businessHours?.length > 0 ? true : false
		this.averageDurationOfStayInMinutesLocal = this.averageDurationOfStayInMinutes
	},
}
</script>

<style scoped>
hr { border: 1px solid #c0c0c0 !important; height: 1px; }
.v-text-field > .v-input__control > .v-input__slot::before { border-style: none !important;}
.v-text-field { padding-bottom: 12px !important; }
.timeSpan { position: relative; background: #f0f0f0; border-radius: 5px; margin-bottom: 10px; margin-top: 0; padding-bottom: 10px; }
.timeSpan.error { outline: 2px solid var(--error-color); }
.timeSpan .error { color: var(--error-color); margin-left: 10px; }
</style>

<style>
.timespanDialog .content { padding: 10px !important; }
.timespanDialog .content-wrapper { padding-top: 0; }
.timespanDialog .IsoDatePicker { box-shadow: none !important; margin: 0 !important; }
.timespanDialog .IsoDatePicker .v-date-picker-controls { padding: 0 !important; }
.timespanDialog .IsoDatePicker .v-date-picker-month { padding: 0 !important; }
</style>