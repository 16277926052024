<template>
	<v-expansion-panels v-model="model">
		<v-expansion-panel :eager="eager" class="disclosureHeader">
			<v-expansion-panel-title :data-cy="dataCy">
				<template v-slot:default>
					<div class="column">
						<div class="row">
							<v-icon v-if="lock" size="24px" color="gray">mdi-lock-outline</v-icon>
							<span :class="smallTitle ? 'small-title' : ''">{{ title }}</span>
							<v-btn v-if="deleteIcon" size="small" icon color="#f34545" style="cursor:pointer; float: right;" @click.stop="$emit('remove')">
								<v-icon>mdi-delete</v-icon>
							</v-btn>
						</div>
						<div v-if="warning" class="row">
							<img src="@/assets/icons/icon-warning.svg" class="icon" />
							<span class="warning-text">{{ message }}</span>
						</div>
						<div v-if="error" class="row">
							<img src="@/assets/icons/icon-error.svg" class="icon error-icon" />
							<span class="error-text">{{ message }}</span>
						</div>
					</div>
				</template>
			</v-expansion-panel-title>
			<v-expansion-panel-text :class="{ disabled: lock }">
				<slot></slot>
			</v-expansion-panel-text>
		</v-expansion-panel>
	</v-expansion-panels>
</template>

<script>
export default {
	props: {
		title: String,
		dataCy: String,
		lock: Boolean,
		warning: Boolean,
		error: Boolean,
		message: String,
		expanded: Boolean,
		deleteIcon: Boolean,
		smallTitle: Boolean,
		eager: Boolean,
	},
	data() {
		return {
		model: -1
	}},
	methods: {
		open() {
			this.model = 0
		},
		close() {
			this.model = -1
		},
		toggle() {
			if (this.model === -1) {
				this.open()
			} else {
				this.close()
			}	
		},
	},
	mounted() {
		if (this.expanded) {
			this.model = 0
		}
	}
}
</script>

<style scoped>
.v-expansion-panel-header { font-size: 20pt !important; padding: 20px 25px 20px 25px !important; border-bottom: 1px solid #dedede; }
.disabled { opacity: 0.75; pointer-events: none; background-color: #fafafa; }
.warning-text { font-size: 14pt !important; color: #ffb400 !important}
.error-text { font-size: 14pt !important; color: #f34545 !important}
.row {
	display: flex;
	align-items: center;
	gap: 10px;
}
.column {
	display: flex;
	flex-direction: column;
	gap: 10px;
}
.icon { width:24px; height:24px; }
.error-icon {filter: brightness(100%) saturate(400%);}
.small-title {
	font-size: 14pt !important;
}

.disclosureHeader { padding-right: 2rem; padding-left: 2rem; }

@media screen and (max-width: 1024px) {
	.disclosureHeader { padding-right: 0; padding-left: 0; }
}
</style>