<template>
	<DataDialog ref="dialogRef" v-model="model" :title="$t('text.transportationProducts')"
		width="70vw" height="95vh"
	>
		<template #title>
			<v-toolbar-title>
				<span>{{ $t("text.editTransportation") }}</span>
			</v-toolbar-title>
		</template>

		<template #sidebar>
			<div v-for="(item, index) in model" :key="index"
				:ref="`sidebar_${item.id}`"
				class="sidebar-options"
				:class="{
					highlighted: sidebarItemActive?.id == item.id,
				}"
				@click="clickSidebar(item)"
			>
				{{ item.name[serviceLocale] }}
			</div>
		</template>

		<template #content="{ model }">
			<FieldSet id="customizeTransportationProducts" infoText="customizeTransportationProductsHelp">
			</FieldSet>

			<!-- TODO: maybe switch to https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API instead of hover -->
			<div class="expandable-section" v-for="(item, index) in model" :key="index" :ref="item.id"
				@mouseover="hoverItem(item)"
			>
				<v-expansion-panels v-model="panelModel[item.id]">
					<v-expansion-panel>
						<v-expansion-panel-title class="panel-header">
							<template v-slot:actions />
								<template v-slot="{ expanded }">
									<slot name="itemHead" :item="item" :expanded="expanded" />
								</template>
						</v-expansion-panel-title>
						<v-expansion-panel-text>
							<slot name="itemBody" :item="item" />
						</v-expansion-panel-text>
					</v-expansion-panel>
				</v-expansion-panels>
			</div>
		</template>

	</DataDialog>
</template>

<script>
import FieldSet from './FieldSet.vue'
import Field from '../../../components/fields/Field.vue'
import DataDialog from '../../../components/common/DataDialog.vue'
import MysSwitch from "../../../components/mys/mys-switch.vue"
import Optional from './Optional.vue'
import OptionalSettings from './OptionalSettings.vue'
import Common from '@/mixins/Common.vue'

export default {
	components: { FieldSet, Field, MysSwitch, Optional, OptionalSettings, DataDialog },
	mixins: [ Common ],
	props: {
		modelValue: Array,
		optionalFields: Array,
		typeName: String,
		showCopyToReturnJourney: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			panelModel: {},
			model: null,
			copyToReturnJourney: false,
			sidebarItemActive: null,
		}
	},
	computed: {
		
	},
	watch: {
		model(n) { this.$emit('update:modelValue', n) },
		modelValue(n) {
			this.model = n
			for (const item of this.model ?? []) {
				this.panelModel[item.id] = 0
			}
		},
	},
	methods: {
		open() {
			this.$refs.dialogRef.open()
		},
		clickSidebar(item) {
			const div = this.$refs[item.id]?.[0]
			div?.scrollIntoView?.({ behavior: 'smooth', block: 'start' })
			this.panelModel[item.id] = 0
			this.sidebarItemActive = item
		},
		hoverItem(item) {
			this.sidebarItemActive = item
			const div = this.$refs[`sidebar_${item.id}`]?.[0]
			div?.scrollIntoView?.({ behavior: 'smooth', block: 'start' })
		},
	},
	async mounted() {
		this.model = this.modelValue
		for (const item of this.model ?? []) {
			this.panelModel[item.id] = 0
		}
	},
};
</script>

<style scoped lang="scss">
.sub-title {
	line-height: normal;
	color: #b1b1b0;
}

.content {
	p {
		font-size: 12px;
		line-height: 16px;
		color: black;
	}

	.title {
		font-size: 17px;
		line-height: 22px;
		font-weight: bold;
	}
}

.expandable-section {
	margin-top: 24px;
}

.section-body {
	margin-left: 12px;
	margin-right: 12px;
}

.days {
	display: flex;
	justify-content: space-between;
	// width: 50%;
	flex-grow: 1;
	padding: 12px;
}
.column {
	width: 50%;
}
.title {
	font-size: 12px;
	line-height: 22px;
	font-weight: bold;
}
.subtitle {
	font-size: 12px;
	line-height: 16px;
	color: #b1b1b0;
	padding: 12px 0;
}
.disabled {
	opacity: 0.5;
	pointer-events: none;
}
.sidebar-options {
	padding: 12px;
	border-bottom: 1px solid #e0e0e0;
	cursor: pointer;
}
.sidebar-options:hover {
	background-color: #0ba7e1;
	color: white;
	// border: none;
}
.highlighted {
	background-color: #0ba7e1;
	color: white;
	// border: none;
}
.panel-header {
	background-color: #f4f4f4;
}

.expandIcon {
	display: flex;
	align-items: center;
	justify-content: left;
	width: calc(18vw + 400px);
	height: 24px;
	border-radius: 50%;
	background-color: #f4f4f4;
}

.sectionTitle {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 24px;
	font-size: 14px;
	font-weight: bold;
}

@media (min-width: 1900px) {
	.sectionTitle {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 80%;
		height: 24px;
	}
}

.sectionTitleEnd {
	display: flex;
	align-items: center;
	justify-content: end;
	width: 30%;
	height: 24px;
}


</style>
