<template>
	<div class="OptionSelectField">
		<v-combobox variant="outlined" density="compact" hide-details return-object style="background-color: #ffffff;" ref="combobox"
			:items="values"
			:item-title="itemText"
			v-model="model"
			:data-cy="dataCy"
			@focus="$emit('focus', $event)"
			:hide-no-data="false"
			@blur="model = lastGoodValue"
		>
			<template #prepend-inner>
				<v-icon>mdi-ticket</v-icon>
			</template>
			<template #prepend-item v-if="canAddNew">
				<v-btn width="100%" elevation="0" @click="addNew" class="addNew">
					<v-icon>mdi-plus</v-icon>
					{{ $t('text.addNewOption') }}
				</v-btn>
			</template>
			<template #no-data v-if="canAddNew && searching">
				<div style="padding: 5px 10px 20px; color: #666;">Could not find <b>{{ model }}</b></div>
				<v-btn width="100%" elevation="0" @click="addNew" class="addNew">
					<v-icon>mdi-plus</v-icon>
					{{ $t('text.addNewOption') }}
				</v-btn>
			</template>
			<template #append-item v-if="canAddNew">
				&nbsp;
			</template>
		</v-combobox>
	</div>
</template>

<script>
import Common from '@/mixins/Common.vue'

export default {
	mixins: [ Common ],
	props: {
		modelValue: Object, // CfObject<ticketType|reduction>
		serviceProviderOptions: Array,
		clientOptions: Array,
		dataCy: String,
		canAddNew: Boolean,
	},
	data: () => ({
		options: {},
		model: null,
		originalValue: null,
		lastGoodValue: null,
	}),
	watch: {
		modelValue(n) {
			this.model = n
			if (!this.originalValue) this.originalValue = this.modelValue
		},
		model(n) {
			// we do not update the container when we dont have a proper value selected
			if (this.searching) return // n = this.lastGoodValue
			if (n) this.lastGoodValue = n
			this.$emit('update:modelValue', n)
		},
		serviceProviderOptions: {
			immediate: true,
			handler (val, oldVal) {
				this.updateOptions()
			}
		},
		clientOptions: {
			immediate: true,
			handler (val, oldVal) {
				this.updateOptions()
			}
		},
	},
	computed: {
		values() {
			// copy from sp and client
			let options = { ...this.options }

			// add the value originally set on the object
			// we do this because the value may not be assigned to the SP any longer
			// but it still is on the product
			// TODO: should we show a warning when this happens to nudge the user to change it?
			//       but the value may be allowed in a different SP..
			if (this.originalValue?.sys?.id && !options[this.originalValue?.sys?.id]) {
				options[this.originalValue?.sys?.id] = this.originalValue
			}

			// sort by title
			let r = Object.values(options)

			// remove bad objects from the array as these values will be shown on the dropdown in frontend and if they have errors they will be shown as empty selections
			for (let o of r) {
				if (o.fields?.title) {
					continue
				} else {
					r.splice(r.indexOf(o), 1)
				}
			}

			r.sort((a, b) => {
				if (!a.fields?.title || !b.fields?.title) return 0
				if (!a.fields?.title[this.serviceLocale]) a.fields.title[this.serviceLocale] = a.fields.title.de
				if (!b.fields?.title[this.serviceLocale]) b.fields.title[this.serviceLocale] = b.fields.title.de
				
				return a?.fields?.title[this.serviceLocale].localeCompare(b?.fields?.title[this.serviceLocale])
			})
			return r
		},
/*		errors() {
			const errors = []
			if (this.error) {
				errors.push(this.$t('text.missingFieldsError'))
			}
			return errors
		},*/
		searching() {
			return typeof this.model == 'string'
		},
	},
	methods: {
		itemText(item, b) {
			if (typeof item === 'string') return item
			return item?.fields?.title?.[ this.serviceLocale ]
				?? item?.fields?.title?.[ 'de' ]
				?? ''
		},
		updateOptions() {
			const options = { ...this.options }

			// merge in the serviceProvider options
			for (let option of this.serviceProviderOptions) {
				if (!option.source) option.source = 'SERVICEPROVIDER'
				options[option.sys.id] = option
			}

			// merge in the defaults from the client
			for (let option of this.clientOptions) {
				//if (!option.defaultAssignment) continue
				if (this.options[option.sys.id]) continue
				if (!option.source) option.source = 'CLIENT'
				options[option.sys.id] = option
			}

			this.options = options
		},
		addNew() {
			this.$emit('show-add-option', this.searching ? this.model : '')
		},
	},
	mounted() {
		this.model = this.modelValue
		this.originalValue = this.modelValue
		if (this.originalValue) {
			this.originalValue.source = 'ORIGINAL'
			this.lastGoodValue = this.modelValue
		}
		this.updateOptions()
	},
}
</script>

<style scoped>
.addNew { position: fixed; bottom: 0; background: white !important; z-index: 999; box-shadow: 0 -3px 20px rgba(0,0,0,0.1) !important; }
</style>