<template>
	<div :class="{ error }" style="display: flex; gap: 16px; align-items: center;">
		<v-switch v-model="model" :label="switchLabel" @focus="onFocus" @blur="onBlur"
			class="mys-switch" inset
			:class="{
				selected: !!model,
			}"
			hide-details
			:data-cy="dataCy"
		/>
		<span class="clear" v-if="field.required != true && modelValue !== undefined && modelValue !== null" @click="$emit('update:modelValue', null)">Clear</span>
	</div>
</template>

<script>
import { field } from './FieldMixin.js'

export default {
	mixins: [ field ],
	props: {
		modelValue: Boolean,
		dataCy: String,
	},
	data: () => ({
		id: 'RG-' + Math.random(),
		model: null,
	}),
	computed: {
		switchLabel() {
			return this.model ? this.trueLabel : this.falseLabel;
		},
		trueLabel() {
			return this.field.control?.settings?.trueLabel ?? 'Yes'
		},
		falseLabel() {
			return this.field.control?.settings?.falseLabel ?? 'No'
		},
	},
	watch: {
		// TODO: on initial load this does not fire!
		value(n) {
			this.model = n
			this.onErrors([
				this.validateRequired(),
			])
		},
	},
	mounted() {
		// this.onErrors([
		// 	this.validateRequired(),
		// ])
	},
}
</script>

<style scoped>
.mys-switch { margin: 0 !important; }
.mys-switch.nolabel { max-width: 44px; --v-input-control-height: 40px; }
.mys-switch .v-switch__track { background-color: #ccc; opacity: 1; }
.mys-switch.selected .v-switch__track { background-color: #00aeef; }
.clear { color: rgb(0, 89, 200); padding: 0; cursor: pointer; }
</style>