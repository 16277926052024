<template>
  <v-container class="pa-0">
    <!-- New Upload -->
    <div v-if="mediaAsset.fields.file">
      <Disclosure :title="mediaAsset.fields.file?.upload?.filename ?? ''" :small-title="true" :expanded="true" :delete-icon="mediaAsset.fields.isURLUpload" @remove="removeFile" :warning="mediaAsset.fields.isPoorQuality">

      <v-col>
          <v-row>
            <v-col>
              <img class="thumbnail" v-if="mediaAsset.fields.file" :src="mediaAsset.fields.file.dataURL" :key="thumbnailHash" style="width: 100%; height: auto; max-width:350px;border-radius:4px"/>
            </v-col>
        
            <v-col>
              <v-label>{{$t('text.title')}}&nbsp;<span class="error-text">({{$t('text.required')}})</span></v-label>
              <LanguageFlag v-model="serviceLocale" class="flag" />
              <v-text-field variant="outlined" density="compact" counter="256" maxLength="256"
              id="title"
              v-model.trim="mediaAsset.fields.title[serviceLocale]"
              :error-messages="mediaAsset.titleError"
              ></v-text-field>
            
              <v-label>{{$t('text.altText')}}</v-label>
              <LanguageFlag v-model="serviceLocale" class="flag" />
              <v-text-field variant="outlined" density="compact" counter="256" maxLength="256"
              v-model="mediaAsset.fields.altText[serviceLocale]" 
              persistent-hint></v-text-field>
              <p class="helpText" v-html="$t('text.altTextHelp')"/>
            </v-col>
        </v-row>

        <div>
          <v-label>{{$t('text.description')}}</v-label>
          <LanguageFlag v-model="serviceLocale" class="flag" />
          <v-textarea variant="outlined" counter="180" maxLength="180"
            v-model="mediaAsset.fields.description[serviceLocale]"></v-textarea>
        </div>

        <!-- Image editing section -->
        <div v-if="mediaAsset.fields.file">
          <div class="mt-4">
            <v-label>{{$t('text.imageEditing')}}</v-label>
            <p class="helpText" v-html="$t('text.imageEditingHelp')"/>
            <br/>

            <div>
              <v-btn class="gradientButton" @click="$refs.mediaDialog.show = true" :disabled="mediaAsset.fields.isURLUpload === true"><v-icon>mdi-crop</v-icon> {{$t('text.openEditor')}}</v-btn>
              <p cv-if="mediaAsset.fields.isURLUpload === true" class="helpText" v-html="$t('text.uploadImageBeforeEdit')"/>
              <br/>
            </div>

            <div>
              <p class="helpText" v-html="$t('text.cropsPreview')"/>

              <v-row class="mt-2">
                <v-col
                  v-for="aspectRatio in aspectRatios"
                  :key="aspectRatio.value"
                  class="mb-4"
                  cols="3"
                >
                  <div>
                    <div class="text-subtitle-2">{{ $t(`text.${aspectRatio.text}`) }}</div>
                    <div class="image-container" >
                      <v-img width="300" cover :aspect-ratio="aspectRatio.value" :src="mediaAsset.fields.file.dataURL" style="border-radius: 4px;"></v-img>
                      <div class="grid-container">
                        <div v-for="(position, index) in positions" :key="index" :class="['grid-item', { active: focalPointForImage === position }]" ></div>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>

          </div>

        </div>
      </v-col>
      
    </Disclosure>
    </div>

    <!-- Edit Image -->
    <template v-if="mediaAsset.fields.media">
      <v-row no-gutters class="pt-4" style="position: relative;">
        <v-col class="thumbnail col-12 col-md-6 pl-0">
          <input type="file" ref="file" id="fileBrowser" accept="image/jpg, image/jpeg, image/png" style="display: none" @change="chooseFile()">
          <img ref="imageDataRef" :src="imgData" style="width: 100%; height: auto; max-width:350px;border-radius:4px"/>
        </v-col>

        <v-col class="col-12 col-md-6 pr-0">
          <div>
            <div>
              <v-label>{{$t('text.title')}} <span class="error-text">({{$t('text.required')}})</span></v-label>
                <LanguageFlag v-model="serviceLocale" class="flag" />
                <v-text-field class="mt-1" variant="outlined" density="compact" counter="256" maxLength="256" 
                id="title"
                v-model.trim="mediaAsset.fields.title[serviceLocale]"
                :error-messages="mediaAsset.titleError"></v-text-field>
            </div>

            <div>
              <v-label>{{$t('text.altText')}}</v-label>
                <LanguageFlag v-model="serviceLocale" class="flag" />
                <v-text-field class="mt-1 " variant="outlined" density="compact" counter="256" maxLength="256"
                v-model="mediaAsset.fields.altText[serviceLocale]"
                persistent-hint></v-text-field>
              <p class="helpText" v-html="$t('text.altTextHelp')"/>
            </div>
          </div>
        </v-col>

        <div style="width: 100%" class="pt-4">
          <v-label>{{$t('text.description')}}</v-label>
          <LanguageFlag v-model="serviceLocale" class="flag" />
          <v-textarea class="mt-1" variant="outlined" counter="180" maxlength="180"
            v-model="mediaAsset.fields.description[serviceLocale]" ></v-textarea>
        </div>

        <!-- Image editing section -->
        <div>
          <div class="mt-4">
            <v-label>{{$t('text.imageEditing')}}</v-label>
            <p class="helpText" v-html="$t('text.imageEditingHelp')"/>
            <br/>

            <div>
              <v-btn class="gradientButton" @click="$refs.mediaDialog.show = true"><v-icon>mdi-crop</v-icon> {{$t('text.openEditor')}}</v-btn>
              <br/>
            </div>

            <div>
              <p class="helpText" v-html="$t('text.cropsPreview')"/>

              <v-row class="mt-2">
                <v-col
                  v-for="aspectRatio in aspectRatios"
                  :key="aspectRatio.value"
                  class="mb-4"
                  cols="3"
                >
                  <div>
                    <div class="text-subtitle-2">{{ $t(`text.${aspectRatio.text}`) }}</div>
                    <div class="image-container" >
                      <v-img width="300" cover :aspect-ratio="aspectRatio.value" :src="imgData" style="border-radius:4px !important"></v-img>
                      <div class="grid-container">
                        <div v-for="(position, index) in positions" :key="index" :class="['grid-item', { active: focalPointForImage === position }]" ></div>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>

            </div>

          </div>
        </div>
      </v-row>
    </template>
    <MediaEditorDialog ref="mediaDialog"
				:showClose="true"
				:confirmLabel="$t('text.confirmSelection')"
				:cancelLabel="$t('text.discardChanges')"
        :nextStepLabel="$t('text.nextStep')"
        :previousStepLabel="$t('text.previousStep')"
				:confirm-handler="confirmMedia"
				:cancelHandler="cancelMedia"
				:title="$t('text.imageEditor')"
        :subtitle="imageSubtitle"
        :stepper="true"
        @step-changed="(val) => step = val"
				:height="'80vh'"
				:width="'70vw'"
        :isValid="!goodQualityImage"
        :fixIndexes="fixIndexes">
				<template #content>
          <ImageEditor
            ref="imageEditor"
            :selectedImage="mediaAsset"
            :edit="imgData ? true : false"
            :step="step"
            @poor-quality="(val) => goodQualityImage = val"
          />
				</template>
    </MediaEditorDialog>

  </v-container>
</template>

<script>
import LanguageFlag from '@/components/common/LanguageFlag.vue'
import Common from '@/mixins/Common.vue'
import Disclosure from '@/components/common/Disclosure.vue'
import MediaEditorDialog from '@/components/common/MediaEditorDialog.vue'
import Dialog from '@/components/common/Dialog.vue'
import ImageEditor from './ImageEditor.vue'

export default {
  name: "MediaItem",
  components: { LanguageFlag, Disclosure, MediaEditorDialog, Dialog, ImageEditor },
  mixins: [Common],
  emits: ['remove-media-file'],
  props: {
		mediaAsset: { type: Object },
    fixIndexes: {
      type: Boolean,
      default: true
	  }
	},

  data() {
      return {
        imgData: '',
        step: 1,
        thumbnailHash: '',
        croppedImageUrl: '',
        aspectRatios: [
        { text: 'square1x1', value: 1 },
        { text: 'landscape16x9', value: 16/9 },
        { text: 'landscape4x3', value: 4/3 },
        { text: 'landscape3x2', value: 3/2 },
        { text: 'portrait3x4', value: 3/4 },
        ],
        positions: [
          'top left',
          'top',
          'top right',
          'left',
          'center',
          'right',
          'bottom left',
          'bottom',
          'bottom right',
        ],
        goodQualityImage: false,
      }
  },

  computed: {
    focalPointForImage() {
      return this.mediaAsset.fields.imageFocusArea?.de || 'center'
    },
    imageSubtitle() {
      // edit image
      if (this.imgData) {
        const width = this.mediaAsset.fields.media.de.fields.file.de.details?.image?.width
        const height = this.mediaAsset.fields.media.de.fields.file.de.details?.image?.height
        if (!width || !height) return ''
        // return this.mediaAsset.fields.media.de.fields.file.de.fileName + '(' + width + 'x' + height + ')'
        return this.mediaAsset.fields.title[this.serviceLocale] + '(' + width + 'x' + height + ')'
      }
      // new image
      else {
        return this.mediaAsset.fields.file.upload.filename + '(' + this.mediaAsset.fields.file.width + 'x' + this.mediaAsset.fields.file.height + ')'
      }
    },
  },

  methods: {
    cropImage(url) {
      // New upload
      if (!this.imgData) {
        this.mediaAsset.fields.file.dataURL = url
        this.thumbnailHash = Math.random()
      }
      // Edit existing image
      else {
        this.imgData = url
        // the actually url on the object is updated in the parent component when the image is saved
        // this.mediaAsset.fields.media.de.fields.file.de.url = url
        this.thumbnailHash = Math.random()
      }
    },
    removeFile() {
      this.$emit('remove-media-file', this.mediaAsset.fields.file)
    },
    setImageData(data) {
      let imageData = this.mediaAsset.fields.media.de.fields.file.de.url

      if (data) {
        imageData = data
      }
      return imageData
    },
    chooseFile() {
      const tmpThis  = this

      const fileList = document.getElementById("fileBrowser").files;
      const fileReader = new FileReader();
      if (fileReader && fileList && fileList.length) {
          fileReader.readAsArrayBuffer(fileList[0]);
          fileReader.onload = function () {
            const imageData = fileReader.result;
            tmpThis.imgData = 'data:image/jpg;base64,' + tmpThis.arrayBufferToBase64(imageData)
          };
      }
    },
    arrayBufferToBase64( buffer ) {
      var binary = ''
      const bytes = new Uint8Array( buffer )
      const len = bytes.byteLength

      for (var i = 0; i < len; i++) {
          binary += String.fromCharCode( bytes[ i ] )
      }

      return window.btoa( binary )
    },
    cancelMedia() {
      this.$refs.mediaDialog.show = false
    },
    confirmMedia() {
      this.$refs.mediaDialog.show = false
      
      // we trigger the getCroppedImageUrl method in the child component - ImageEditor to get the cropped image URL
      const croppedImageUrl = this.$refs.imageEditor.getCroppedImageUrl()
      this.cropImage(croppedImageUrl)

      const focalPoint = this.$refs.imageEditor.focalPoint
      // we call it imageFocusArea because James expects this model name
      // this.mediaAsset.fields.imageFocusArea = { de: focalPoint }
      this.mediaAsset.fields.imageFocusArea = { de: focalPoint }
    },
  },
  created() {
    this.imgData = this.mediaAsset.fields.media?.de?.fields?.file?.de?.url ? this.mediaAsset.fields.media?.de.fields.file.de.url : ''
  },
  mounted() {
    this.mediaAsset.fields["title"] = this.mediaAsset.fields.title ? this.mediaAsset.fields.title : { de: '', en: '', fr: '', it: '', nl: '' }
    this.mediaAsset.fields["description"] = this.mediaAsset.fields.description ? this.mediaAsset.fields.description : { de: '', en: '', fr: '', it: '', nl: '' }
    this.mediaAsset.fields["altText"] = this.mediaAsset.fields.altText ? this.mediaAsset.fields.altText : { de: '', en: '', fr: '', it: '', nl: '' }
  }
}
</script>

<style scoped>
.flag { position: absolute; z-index: 10; margin-top: 20px; margin-left: -15px; transform: scale(0.8); }
.title {
	border-radius: 4px;
	border: solid 1px rgba(0, 0, 0, 0.1);
	background-color: #f2f2f2;
	margin-top: 10px;
	margin-bottom: 10px;
}

.image-container {
  margin: auto;
  position: relative;
}

.grid-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  
}

.grid-item {
	cursor: auto;
}

.grid-item.active {
  background-color: rgba(255, 180, 0, 0.4);
}

</style>
