<template>
	<div>
		<div class="field left-border">
			<p class="subTitle">{{$t('text.fixedPersonalisationFields')}}</p>
			<p class="helpText" v-html="$t('text.fixedPersonalisationHelp')"/>

			<!-- Fixed Personalisations -->
			<div v-for="(fixedPersonalisationGroup) in fixedPersonalisationGroups" :key="fixedPersonalisationGroup.sys.id"
				style="display: flex; gap: 10px; align-items: center;"
			>
				<v-checkbox hide-details
					:disabled="disabled || fixedPersonalisationGroup.fields.title.de === 'Name'"
					v-model="fixedPersonalisationGroup.addl.checked"
					@update:modelValue="updateChecked(fixedPersonalisationGroup)"
					style="flex-grow: 0;"
				/>
				<v-text-field variant="outlined" density="compact" hide-details disabled style="background-color: #f4f4f4 !important; flex-grow: 1;" v-model="fixedPersonalisationGroup.fields.title[selectedLocale]"/>
				<v-label class="pr-1">{{$t('text.mandatory')}}</v-label>
				<mys-switch
					v-model="fixedPersonalisationGroup.addl.required"
					:disabled="!fixedPersonalisationGroup.addl.checked || fixedPersonalisationGroup.fields.title.de === 'Name'"
				/>
			</div>
		</div>

		<div class="field left-border">
			<p class="subTitle" v-html="$t('text.additionalPersonalisationFields')"/>
			<p class="helpText" v-html="$t('text.additionalPersonalisationHelp')"/>

			<!-- Additional Personalisation -->
			<div v-if="additionalPersonalisations?.length"
				style="margin: 10px 0; display: flex; flex-direction: column; gap: 10px;"
			>
				<div v-for="(additionalPersonalisation, index) in additionalPersonalisations" :key="additionalPersonalisation.sys.id"
					style="display: flex; gap: 10px; align-items: center; flex-wrap: wrap;"
				>
					<template v-if="additionalPersonalisation?.fields?.personalisationGroup?.de?.fields">
						<!-- Title -->
						<v-menu style="z-index: 1000">
							<template v-slot:activator="{ props }">
								<v-text-field v-bind="props" variant="outlined" hide-details density="compact" maxLength="35" :label="$t('text.enterTitle')"
									v-model="additionalPersonalisation.fields.personalisationGroup.de.fields.personalisationFields.de[0].fields.label[selectedLocale]"
									:error-messages="additionalPersonalisation.fields.personalisationGroup.errors && additionalPersonalisation.fields.personalisationGroup.errors.title ? additionalPersonalisation.fields.personalisationGroup.errors.title: ''"
									style="min-width: 150px;"
								/>
							</template>
							<v-list>
								<v-list-item v-for="(locale) in locales" :key="locale.code">
									<v-row align="center" no-gutters>
										<LanguageFlag v-model="locale.code" style="cursor:pointer; margin-right:-8px; margin-top:-24px" />
										<v-text-field @click.stop="" @keydown.stop="" variant="outlined" maxLength="35" v-model="additionalPersonalisation.fields.personalisationGroup.de.fields.personalisationFields.de[0].fields.label[locale.code]"></v-text-field>
									</v-row>
								</v-list-item>
							</v-list>
						</v-menu>
						<!-- Input Type -->
						<v-select variant="outlined" hide-details density="compact"
							:label="$t('text.selectType')"
							v-model="additionalPersonalisation.fields.personalisationGroup.de.fields.personalisationFields.de[0].fields.inputType.de"
							:error-messages="additionalPersonalisation.fields.personalisationGroup.errors && additionalPersonalisation.fields.personalisationGroup.errors.inputType ? additionalPersonalisation.fields.personalisationGroup.errors.inputType: ''"
							:items="allowedTypes"
							:item-title="item => item.value ? $t('text.' + item.value) : ''"
							style="min-width: 150px;"
						/>
						<div style="display: flex; gap: 10px; align-items: center;">
							<!-- Option List -->
							<v-menu style="z-index:1000" v-if="additionalPersonalisation.fields.personalisationGroup.de.fields.personalisationFields.de[0].fields.inputType.de==='List'" v-show="toggleOptionList(index)">
								<template v-slot:activator="{ props }">
									<v-btn class="gradient-button-align mt-0" size="small" elevation="0" v-bind="props"
										:style="additionalPersonalisation.fields.personalisationGroup.errors && additionalPersonalisation.fields.personalisationGroup.errors.optionValues ? 'border-color:red !important':''"
									>
										<v-icon>mdi-format-list-bulleted</v-icon>
										<v-avatar
											v-if="additionalPersonalisation.fields.personalisationGroup.de.fields.personalisationFields.de[0].fields.options &&
												additionalPersonalisation.fields.personalisationGroup.de.fields.personalisationFields.de[0].fields.options.de.length > 0"
											size="24px" style="position:absolute;margin-top:-20px;margin-right:-30px;z-index:1002" color="#ff5252">
											<span style="color:#ffffff;font-size:10pt;font-weight:bold">{{additionalPersonalisation.fields.personalisationGroup.de.fields.personalisationFields.de[0].fields.options.de.length }}</span>
										</v-avatar>
									</v-btn>
								</template>
								<v-list style="width: 350px; height: 300px; overflow: auto;" v-if="additionalPersonalisation.fields.personalisationGroup.de.fields.personalisationFields?.de?.[0]?.fields?.options">
									<!-- Option Item -->
									<v-list-item v-for="(option) in additionalPersonalisation.fields.personalisationGroup.de.fields.personalisationFields.de[0].fields.options.de" :key="option.key" @click.stop="" @keydown.stop="">
										<v-menu style="z-index:1003">
										<template v-slot:activator="{ props }">
											<v-row no-gutters align="center" justify="center" v-bind="props" class="my-2">
												<v-text-field variant="outlined" density="compact" hide-details style="width:90%"
													:label="$t('text.enterLabel')"
													v-model="option.values[selectedLocale]"
													:error-messages="option.errors"
										            @keydown.stop=""
												/>
												<v-icon color="#fb3640" style="padding-left:5px" @click.stop="removeOption(additionalPersonalisation, option.key)">mdi-delete</v-icon>
											</v-row>
										</template>

										<v-list>
											<v-list-item v-for="(locale) in locales" :key="locale.code">
												<v-row align="center" no-gutters>
													<LanguageFlag v-model="locale.code" style="cursor:pointer;margin-right:-8px;margin-top:-24px" />
													<v-text-field @click.stop="" @keydown.stop="" variant="outlined" v-model="option.values[locale.code]"></v-text-field>
												</v-row>
											</v-list-item>
										</v-list>
										</v-menu>
									</v-list-item>

									<v-btn class="gradientButton" style="margin-left:15px" elevation="0" @click.stop="addOption(additionalPersonalisation)"><v-icon>mdi-plus</v-icon>{{ $t('text.addOption') }}</v-btn>
								</v-list>
							</v-menu>
							<!-- Required switch -->
							<v-label class="pr-1">{{$t('text.mandatory')}}</v-label>
							<mys-switch v-model="additionalPersonalisation.fields.required.de"
								style="flex-grow: 0;"
							/>
							<!-- Delete entry -->
							<v-btn elevation="0" @click="removePersonalisation(additionalPersonalisation, index)"><v-icon size="24px" color="#fb3640">mdi-delete</v-icon></v-btn>
						</div>
					</template>
				</div>
			</div>
			<v-btn class="gradientButton" elevation="0" @click="addAdditionalPersonalisationRow()"><v-icon>mdi-plus</v-icon>{{ $t('text.addPersonalisationOption') }}</v-btn>
		</div>

		<div style="height: 30px;"></div>
	</div>
</template>

<script>
import LanguageFlag from '@/components/common/LanguageFlag.vue';
import Common from '@/mixins/Common.vue'

export default {
	name: 'PersonalisationFields',
	components: {LanguageFlag},
	mixins: [ Common ],
	props: {
		ticketOption:Object,
		disabled:Boolean
	},
	data: () => ({
		fixedPersonalisationGroups: [],
		additionalPersonalisations: [],
		allowedTypes: [{ value: 'List' }, { value: 'Text' }],
	}),
	watch: {
		// TODO: ???
		fixedPersonalisationGroup: {
			deep: true
		},
		selectedLocale() {
			//Sort Personalisations
			const nameIndex = this.fixedPersonalisationGroups.findIndex(item => item.fields.title.en === "Name")
			const namePersonalisation = this.fixedPersonalisationGroups[nameIndex]
			this.fixedPersonalisationGroups.splice(nameIndex, 1)
			this.fixedPersonalisationGroups.sort(this.compare)
			this.fixedPersonalisationGroups.unshift(namePersonalisation)
		}
	},
	methods: {
		resetError(personalisationGroup, key) {
		},
		addOption(additionalPersonalisation) {
			let index = additionalPersonalisation.fields.personalisationGroup.de.fields.personalisationFields.de[0].fields.options.de.length
			additionalPersonalisation.fields.personalisationGroup.de.fields.personalisationFields.de[0].fields.options.de.push({key:index, values:{ de: '', en: '', fr: '', it: '', nl: '' }})
		},
		removeOption(additionalPersonalisation, optionKey) {
			let index = additionalPersonalisation.fields.personalisationGroup.de.fields.personalisationFields.de[0].fields.options.de.findIndex(x => x.key === optionKey)
			additionalPersonalisation.fields.personalisationGroup.de.fields.personalisationFields.de[0].fields.options.de.splice(index, 1)
			//update keys of remaining options
			for (let i=0; i < additionalPersonalisation.fields.personalisationGroup.de.fields.personalisationFields.de[0].fields.options.de.length; i++) {
				additionalPersonalisation.fields.personalisationGroup.de.fields.personalisationFields.de[0].fields.options.de[i].key = i
			}
		},
		updateChecked(personalisationGroup) {
			if (!personalisationGroup.addl.checked) {
				personalisationGroup.addl.required = false
			}
			this.$forceUpdate()
		},
		addAdditionalPersonalisationRow() {
			this.additionalPersonalisations.push({
				checked: false,
				sys: {
					id: this.additionalPersonalisations.length
				},
				fields: {
					title: { de: '', en: '', fr: '', it: '', nl: '' },
					required: { de: '' },
					personalisationGroup: {
						de: {
							sys: { id: ''},
							fields: {
								title: { de: '', en: '', fr: '', it: '', nl: '' },
								personalisationFields: {
									de: [{
										fields: {
											inputType: { de: 'Text' },
											label: { de: '', en: '', fr: '', it: '', nl: '' },
											options: { de: [] }
										}
									}]
								}
							}
						}
					}
				}
			})
		},
		toggleOptionList(index) {
			if (this.additionalPersonalisations[index].fields.personalisationGroup.de.fields.personalisationFields.de[0].fields.inputType.de==='List') {
				return true
			} else {
				return false
			}
		},
		removePersonalisation(additionalPersonalisation, index) {
			this.additionalPersonalisations.splice(index, 1)
			this.$emit('remove-additional-personalisation', additionalPersonalisation)
		}
	},
  async mounted() {
    let personalisationGroup

    //Set fixedPersonalisationGroups
    if (this.$store.state.coreConfig.fields.personalisationGroups.de?.length > 0) {
      for (personalisationGroup of this.$store.state.coreConfig.fields.personalisationGroups.de) {
        let ticketPersonalisation = this.ticketOption.fields.ticketPersonalisations?.de?.find(item => item.fields.personalisationGroup.de.sys.id === personalisationGroup.sys.id)
        personalisationGroup["addl"] = personalisationGroup.fields.title.de === 'Name' ? {checked:true, required:true} : {checked:false, required:false}

        if (ticketPersonalisation) {
          personalisationGroup.addl.checked = true

          if (ticketPersonalisation.fields.required.de === true) {
            personalisationGroup.addl.required = true
          }
        }

        this.fixedPersonalisationGroups.push(personalisationGroup)
      }
    }

    //Set additionalPersonalisationGroups
    if (this.ticketOption.fields.ticketPersonalisations?.de?.length > 0) {
      for (let ticketPersonalisation of this.ticketOption.fields.ticketPersonalisations.de) {
        let fixedTicketPersonalisation = this.$store.state.coreConfig.fields.personalisationGroups.de.find(item => item.sys.id === ticketPersonalisation.fields.personalisationGroup.de.sys.id)
        
        if (!fixedTicketPersonalisation) {
          personalisationGroup = ticketPersonalisation.fields.personalisationGroup
          personalisationGroup["addl"] = {checked:true, required:false}
          personalisationGroup.addl.required = ticketPersonalisation.fields.required.de

          this.additionalPersonalisations.push(ticketPersonalisation)
        }
      }
    }

    //Sort Personalisations
    const nameIndex = this.fixedPersonalisationGroups.findIndex(item => item.fields.title.en === "Name")
    const namePersonalisation = this.fixedPersonalisationGroups[nameIndex]
    this.fixedPersonalisationGroups.splice(nameIndex, 1)
    this.fixedPersonalisationGroups.sort(this.compare)
    this.fixedPersonalisationGroups.unshift(namePersonalisation)
  }
}
</script>

<style scoped lang="scss">

.selector-wrapper {
  margin: -12px !important;
  cursor: pointer;
  flex: 1 0 auto;
  overflow-x: auto;
}
.items-section {
  border-right: 1px solid rgba(0,0,0,.12);
}
.item-row {
  &:hover, &.highlited {
    background: #04aeef;

    * {
      color: #fff;
    }
  }
}
.v-list-item .v-list-item__subtitle, .v-list-item .v-list-item__title {
  line-height: 2 !important;
}
.personalisationGroupCol {
	height: 38px;
	border-radius: 4px;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
	border: solid 1px rgba(0, 0, 0, 0.1);
	background-color: #ffffff;
}
.personalisationGroupCol label, .timeframe-contingent-row label {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
div.field .v-input { margin-top: 0; }
table { margin-top:15px;margin-bottom:15px;padding:10px;width:100%; }
table th { text-align: left; }
table td { padding-right: 10px; vertical-align: bottom; }
</style>
