<template>
	<DialogV2 ref="dialog"
		:title="$t('text.translationWizardTitle')"
		:confirmLabel="$t('text.confirmSelection')"
		:onConfirm="confirmTranslations"
	>
		<template #sidebar>
			<LanguageSidebar :checkIfHasMissingTranslations="checkIfHasMissingTranslations" />
		</template>

		<template #content>
			<div class="translation-wizard-inputs">
				<!-- All Languages -->
				<template v-if="activeTranslation === 'all'">
					<div v-for="(key, index) in Object.keys(fields)" :key="key" :class="index ? 'py-4' : 'pb-4'">
						<TranslateableField
							:typeName="typeName"
							:fieldName="key"
							:modelValue="getModelValue(model.fields, fields[key].id)"
							:fieldLocale="activeTranslation"
							:locales="displayedLocales"
							:ticketOnly="ticketOnly"
						/>
					</div>
				</template>
				<!-- Per Language -->
				<template v-else v-for="key in Object.keys(fields)">
					<TranslateableField
						:key="key"
						v-if="fields[key].section ? hasField(fields[key].section, key, model) : true"
						:typeName="typeName"
						:fieldName="key"
						:modelValue="getModelValue(model.fields, fields[key].id)"
						:fieldLocale="activeTranslation"
						:locales="displayedLocales"
						:ticketOnly="ticketOnly"
					/>
				</template>
			</div>
		</template>
	</DialogV2>
</template>

<script>
import Common from '@/mixins/Common.vue'
import LanguagesNavigation from '@/mixins/LanguagesNavigation.vue'
import DialogV2 from '@/components/common/DialogV2.vue'
import LanguageSidebar from '@/components/common/LanguageSidebar.vue'
import TranslateableField from './fields/TranslateableField.vue'

export default {
	name: 'TranslationWizardDialog',
	props: {
		modelValue: Object,
		typeName: String,
	},
	mixins: [ Common, LanguagesNavigation ],
	components: { DialogV2, LanguageSidebar, TranslateableField },
	emits: [ 'update:modelValue' ],
	data() {
		return {
			currentStep: 1,
			model: null,
		}
	},
	watch: {
		modelValue(value) {
			this.model = value
		},
	},
	computed: {
		activeTranslation() {
			return this.$store.state.activeTranslation
		},
		displayedLocales() {
			return this.languageNavigationItems.reduce((locales, { code }) => {
				if (code !== "all" && (this.activeTranslation === "all" || this.activeTranslation === code)) {
					locales.push(code)
				}

				return locales
			}, [])
		},
		fields() {
			return this.$store.state.fieldModels.find(({ name }) => name === this.typeName)?.fields ?? {}
		},
		ticketOnly() {
			return this.modelValue.fields?.contentMode?.de === 'ticket'
		},
	},
	methods: {
		getModelValue(data, key) {
			if (!data) return undefined
			if (key.indexOf('.') === -1) return data[key]

			let value = data

			key.split('.').forEach(keyPart => {
				value = value[keyPart]
			})

			return value
		},
		open() {
			return this.$refs.dialog.open()
		},
		close() {
			return this.$refs.dialog.close()
		},
		checkIfHasMissingTranslations(locale) {
			return Object.keys(this.fields)?.some(key => {
				if (this.fields[key].section && !this.hasField(this.fields[key].section, key, this.model)) return false

				return !this.getModelValue(this.model.fields, this.fields[key].id)?.[locale]
			})
		},
		confirmTranslations() {
			this.$emit('update:modelValue', this.model)
		},
	},
	mounted() {
		this.model = this.modelValue
	},
}
</script>

<style lang="scss">
.translation-wizard-inputs {
	display: flex;
	position: relative;
	flex-direction: column;
	flex-grow: 1;

	&.all {
		.input-field>.input-field-content {
			padding: 16px 0;
		}
	}
}
</style>