<template>
	<div style="margin: 10px 0">
		<div class="add-button">
			<v-btn
				class="gradientButton"
				elevation="0"
				@click.stop="handleClick"
				:data-cy="dataCy"
				style="margin-left: 10px"
			>
				<v-icon>{{ update ? 'mdi-pencil' : 'mdi-plus' }}</v-icon>
				{{ $t('text.' + (update ? 'btnEdit' : 'btnAdd'), { x: $t('text.' + buttonText) }) }}
			</v-btn>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		buttonText: { type: String, default: '' },
		update: { type: Boolean, default: false },
		dataCy: { type: String, default: 'add-button' }
	},
	// emit all button events to parent
	methods: {
		handleClick() {
			this.$emit('click')
		},
	},
}
</script>

<style scoped>
.add-button {
	display: flex; align-items: center; float: right; padding-bottom: 10px;
}
</style>
