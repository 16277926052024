<template>
	<div>
		<Disclosure :title="$t('text.publicInfoTitle')" :error="sectionMessage.error" :message="sectionMessage.message"
			:lock="!userMayEditFields" :expanded="true" data-cy="publicInfo" ref="SECTION_publicInfo"
		>
			<!-- Would this be part of field.validations? -->
			<!-- :error-messages="productTitleErrors" -->
			<Field v-if="hasField('publicInfo', 'title', model)" typeName="Service" fieldName="title" v-model="model.fields.title"
				:disabled="productIsExternal" />

			<Field v-if="hasField('publicInfo','longDescription', model)" typeName="Service" fieldName="longDescription" v-model="model.fields.longDescription"
				:disabled="!userMayEditFields" :errorMessages="longDescriptionErrors" />

			<!-- Need to add/remove required label on condition! Can this be handled by API? -->
			<!-- :required="!productIsExternal" -->
			<Field v-if="hasField('publicInfo','shortDescription', model)" typeName="Service" fieldName="shortDescription" v-model="model.fields.shortDescription"
				:disabled="!userMayEditFields" :errorMessages="shortDescriptionErrors" />

			<Field v-if="hasField('publicInfo','importantInfo', model)" typeName="Service" fieldName="importantInfo" v-model="model.fields.importantInformation"
				:disabled="!userMayEditFields" :required="!productIsExternal" />
		</Disclosure>
	</div>
</template>

<script>
import ImportantInfo from './ImportantInfo.vue'
import TextArea from '@/components/common/TextArea.vue'
import Common from '@/mixins/Common.vue'
import Disclosure from '@/components/common/Disclosure.vue'
import isEqual from 'lodash/isEqual'
import Field from '@/components/fields/Field.vue'

export default {
	name: 'PublicInfo',
	components: { ImportantInfo, TextArea, Disclosure, Field },
	mixins: [ Common ],
	props: {
		product: Object,
		updateModel: Boolean,
		productIsExternal: Boolean,
	},
	data() { return {
		model: {},
		initData: {},

		sectionMessage: {
			error: false,
			message: '',
		},
		
		productTitleErrors: [],
		longDescriptionErrors: [],
		shortDescriptionErrors: [],

		updateNeeded: true
	}},
	watch: {
		updateModel() {
			this.model = this.valueToModel(this.product)
			this.setInitData()
		},
		longDescriptionErrors(n) {
			if (n.length) {
				this.setSectionError(this.sectionMessage, this.$t('text.longDescriptionRequired'))
			}
		},
		shortDescriptionErrors(n) {
			if (!this.productIsExternal && n.length) {
				this.setSectionError(this.sectionMessage, this.$t('text.shortDescriptionRequired'))
			}
		},
		productTitleErrors(n) {
			if (n.length) {
				this.setSectionError(this.sectionMessage, this.$t('text.titleRequiredError'))
			}
		},
		// we need to listen on the title field to update the title when the service is duplicated
		'product.fields.title': {
			handler: function (newTitle) {
				this.model.fields.title = this.valueToModel(newTitle)
			},
			deep: true
		},
	},
	methods: {
		validateAllFields() {
			let allFieldsAreValid = true
			this.resetSectionError(this.sectionMessage)

			const isTitleValid = this.validateTitle(true)
			const isLongDescriptionValid = this.validateLongDescription()
			const isShortDescriptionValid = this.validateShortDescription()

			if (!isTitleValid || !isLongDescriptionValid || !isShortDescriptionValid) {
				allFieldsAreValid = false
				this.setSectionError(this.sectionMessage, this.$t('text.missingFieldsError'))
			}

			return allFieldsAreValid
		},
		validateTitle(runValidation = false) {
			let isValid = true
			const atLeastOneLocaleValueIsSet = this.validateTextLocales(this.model.fields.title)
			this.productTitleErrors = []

			if (runValidation && !atLeastOneLocaleValueIsSet) {
				isValid = false
				this.productTitleErrors.push(this.$t('text.nameRequired'))
			}
			else if (!atLeastOneLocaleValueIsSet) {
				isValid = false
				this.setSectionError(this.sectionMessage, this.$t('text.titleRequiredError'))
			}

			if (isValid === true && this.sectionMessage.message === this.$t('text.titleRequiredError')) {
				this.resetSectionError(this.sectionMessage)
			}
			this.$emit('update-product-title', this.model.fields.title)
			return isValid
		},
		validateLongDescription() {
			let isValid = true
			const atLeastOneLocaleValueIsSet = this.validateTextLocales(this.model.fields.longDescription)
			this.longDescriptionErrors = []

			if (!atLeastOneLocaleValueIsSet) {
				isValid = false
				this.longDescriptionErrors.push(this.$t('text.longDescriptionRequired'))
			}

			return isValid
		},
		validateShortDescription() {
			let isValid = true
			const atLeastOneLocaleValueIsSet = this.validateTextLocales(this.model.fields.shortDescription)
			this.shortDescriptionErrors = []

			if (!this.productIsExternal && !atLeastOneLocaleValueIsSet) {
				isValid = false
				this.shortDescriptionErrors.push(this.$t('text.shortDescriptionRequired'))
			}

			return isValid
		},
		valueToModel(v) {
			return JSON.parse(JSON.stringify(v ?? {}))
		},
		setInitData() {
			const initModel = JSON.parse(JSON.stringify(this.model))
			this.initData = {}

			if (this.hasField('publicInfo','title', this.model)) {
				this.initData.title = initModel.fields.title
			}
			if (this.hasField('publicInfo','longDescription', this.model)) {
				this.initData.longDescription = initModel.fields.longDescription
			}
			if (this.hasField('publicInfo','shortDescription', this.model)) {
				this.initData.shortDescription = initModel.fields.shortDescription
			}
			if (this.hasField('publicInfo','importantInfo', this.model)) {
				this.initData.importantInformation = initModel.fields.importantInformation
			}
		},
		sendData() {
			let data = {}

			if (this.hasField('publicInfo','title', this.model)) {
				data.title = this.model.fields.title
			}
			if (this.hasField('publicInfo','longDescription', this.model)) {
				data.longDescription = this.model.fields.longDescription
			}
			if (this.hasField('publicInfo','shortDescription', this.model)) {
				data.shortDescription = this.model.fields.shortDescription
			}
			if (this.hasField('publicInfo','importantInfo', this.model)) {
				data.importantInformation = this.model.fields.importantInformation
			}

			data.changed = !isEqual(data, this.initData)
			
			return data
		},
	},
	created() {
		this.model = this.valueToModel(this.product)
		this.setInitData()
	},
	updated() {
		if (this.updateModel === true) {
			this.updateNeeded = false
		}
	},
}
</script>