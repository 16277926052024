<template>
	<div>
<!-- TODO: inline messages instead? -->
		<Alert style="margin-top:4em" v-model="errorTitle">{{ errorDetail }}</Alert>
		<Alert style="margin-top:4em" v-model="successTitle" type="success">{{ successDetail }}</Alert>
		<loading v-model:active="loading" :is-full-page="true" color="#4caf50"></loading>

		<!-- TODO: SCR: serviceProviderOptions needs to come from the channelAssignments -->
		<OptionSelectField
			v-model:serviceProviderOptions="serviceProviderOptions"
			:clientOptions="clientOptions"
			v-model="model"
			:canAddNew="true"
			@show-add-option='addOptionStart'
			@focus="$emit('focus', $event)"
			:data-cy="dataCy"
			:error="error"
		/>
		<I18nStringDialog v-if="editItem"
			:title="$t('text.addNewOption')"
			v-model="editItem"
			@cancel="editItem = null"
			@confirm="addOption"
		/>
	</div>
</template>

<script>
import Common from '@/mixins/Common.vue'
import OptionSelectField from './OptionSelectField.vue'
import Dialog from '@/components/common/Dialog.vue'
import Loading from 'vue-loading-overlay'
import Alert from '@/components/common/Alert.vue'
import I18nStringDialog from '../I18nStringDialog.vue'

export default {
	mixins: [ Common ],
	components: { OptionSelectField, Dialog, Loading, Alert, I18nStringDialog },
	props: {
		modelValue: Object,
		dataCy: String,
		error: Boolean,
	},
	data: () => ({
		model: null,
		editItem: null,
		serviceProvider: '',
		errorTitle: '',
		errorDetail: '',
		successTitle: '',
		successDetail: '',
		loading: false,
	}),
	computed: {
		clientOptions() {
			return this.$store.state.selectedClient.fields.defaultAssignments.de.filter(option => option.sys?.contentType?.sys?.id === 'reduction')
		},
		serviceProviderOptions() {
			return this.$store.state.selectedServiceProvider?.fields?.reductions?.de?.length > 0 ? this.$store.state.selectedServiceProvider.fields.reductions.de : []
		},
	},
	watch: {
		modelValue(n) { this.model = n },
		model(n) { this.$emit('update:modelValue', n) },
	},
	methods: {
		addOptionStart(search) {
			const m = {}
			this.locales.forEach(l => m[l.code] = search)
			this.editItem = m
		},
		async addOption() {
			this.loading = true
			let data = {
				itemType: 'reduction',
				name: this.editItem,
				clientId: this.$store.state.selectedClient.sys.id,
				serviceProviderId: this.userIsOperator ? undefined : this.serviceProvider.sys.id,
			}

			try {
				const res = await this.$httpPost(`/option`, data)

				if (this.userIsOperator) {
					await this.$httpPost('/default-assignment', {
						clientId: this.$store.state.selectedClient.sys.id,
						optionId: res.option.sys.id,
						defaultAssignment: true
					})
					this.$store.state.selectedClient.fields.reductions.de.push(res.option)
					this.model = res.option
				}
				else {
// TODO: test!
					this.serviceProvider = res.serviceProvider
					this.$store.state.selectedServiceProvider = this.serviceProvider
					this.model = res.option
				}
			}
			catch (error) {
				console.error(error)
				this.errorTitle = this.$t('text.ERROR')

				if (error?.response?.data?.error === 'You already have this option (client default)') {
					this.errorDetail = this.$t('text.optionAlreadyAssignedError')
				}
				else if (error?.response?.data?.error === 'This service option is already assigned to your profile') {
					this.errorDetail = this.$t('text.alreadyAssignedError')
				}
				else {
					if (error.response) {
						if (error.response.data.error) {
							this.errorDetail = error.response.data.error
						}
					}
					else {
						this.errorDetail = error
					}
				}
			}
			this.loading = false
			this.editItem = null
		},
	},
	mounted() {
		this.model = this.modelValue
		this.serviceProvider = this.$store.state.selectedServiceProvider
	},
}
</script>

<style scoped>
</style>