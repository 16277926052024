<template>
	<Step id="tickets" icon="mdi-ticket-outline" v-if="modelValue">
		<Section id="tickets" open>
			<TicketsInfo2 ref="ticketsSection"
				:modelValue="modelValue.fields.tickets.de"
				:pricingType="modelValue.fields.pricingType.de"
			/>
		</Section>
		<Section id="additionalSettings" open>
			<AdditionalSettings ref="additionalSettingsSection" :modelValue="modelValue.fields.personAmountPerAgeGroup?.de" />
		</Section>
	</Step>
</template>

<script>
import Step from './Step.vue'
import Section from './Section.vue'
import TicketsInfo2 from './TicketsInfo2.vue'
import AdditionalSettings from './AdditionalSettings.vue'

export default {
	components: { Section, Step, TicketsInfo2, AdditionalSettings },
	props: {
		modelValue: Object,
		linkedEntries: Object,
		salesChannels: Array,
		products: Array,
	},
	mounted() {
		if (!this.modelValue.fields.personAmountPerAgeGroup?.de)
			this.modelValue.fields.personAmountPerAgeGroup = { de: [] }
	},
}
</script>

<style scoped>
</style>
