<template>
	<div class="ProfileColorCalendar" style="display: flex; gap: 10px;">
		<div style="flex: 1;">
			<slot name="header" />
			<table style="width: 100%;">
				<tr v-for="profile, ts of profiles ?? []" :key="ts"
					class="profile"
					:class="{
						selected: selectedProfile?.id == profile.id,
					}"
					@mousedown="selectProfile(profile)"
				>
					<td class="details">
						<v-icon style="position: absolute; margin: 6px; font-size: 12pt; color: red;" v-if="profile.isRemove">mdi-cancel</v-icon>
						<v-menu :close-on-content-click="false" v-if="!profile.isRemove">
							<v-color-picker class="PCC" v-model="profile.color" hide-canvas hide-inputs mode="rgb" show-swatches :swatches="swatches" />
							<template #activator="{ props }">
								<v-icon v-bind="props" style="position: absolute; margin: 6px; font-size: 12pt; opacity: 0.5;">mdi-invert-colors</v-icon>
							</template>
						</v-menu>
						<div>
							<input style="border-radius: 20px; border: 0; padding: 3px 13px 3px 30px;" :style="{ background: profile.color }" :disabled="profile.isRemove" v-model="profile.name" />
						</div>
						<slot name="item" :profile="profile" />
						<div class="hint" v-if="selectedProfile?.id == profile.id">
							Paint onto the calendar &rarr;
						</div>
					</td>
					<td width="0" style="vertical-align: top;"><v-icon @click="profiles.splice(ts, 1)" v-if="profile.deletable !== false && !profile.isRemove">mdi-delete</v-icon></td>
				</tr>
			</table>
		</div>
		<ColorCalendar
			ref="calendar"
			:calendar="calendar"
			:dates="dates"
			:profiles="profiles"
			:focusStart="focusStart"
			:focusEnd="focusEnd"
			:paintingProfile="selectedProfile"
			@focusRangeChanged="focusStart = $event.focusStart; focusEnd = $event.focusEnd"
			@painted="paint($event.profile, $event.days)"
		/>
	</div>
</template>

<script lang="ts">
import moment from 'moment'
import ColorCalendar from './ColorCalendar.vue'

// TODO: on delete profile: remove that id from the data
//       -> needs event instead of direct splice

export default {
	components: { ColorCalendar },
	props: {
		modelValue: Array,
	},
	data: () => ({
		weekdays: [ 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday' ],
		editingDay: null,
		selectedDay: null,
		dayMenu: false,
		jumpMenu: false,
		jumpSearch: '',
		selectedProfile: null,
		timespanFocus: null, // 'start' | 'end' | 'weekdays' | null
		focusStart: moment().startOf('month'),
		focusEnd: moment().endOf('month'),
		profileIds: {},
		swatches: [
			['#EEEEEE', '#FFCCCC', '#CCFFCC', '#CCCCFF'],
			['#CCCCCC', '#FF9999', '#99FF99', '#9999FF'],
			['#AAAAAA', '#FF0000', '#00FF00', '#0000FF'],
			['#888888', '#CC0000', '#00CC00', '#0000CC'],
			['#666666', '#990000', '#009900', '#000099'],
		],
	}),
	computed: {
		profiles() {
			return this.modelValue
		},
		// TODO: move some common concerns of these functions into exports of ColorCalendar
		dates() {
			// TODO
			// find min and max date
			let minDate = '2023-01-01'
			let maxDate = '2024-12-31'
			const todayDate = moment().startOf('day')
			const today = todayDate.format('YYYY-MM-DD')
			// always include today in the range
			if (today < minDate) minDate = today
			if (today > maxDate) maxDate = today

			const fromDate = moment(minDate).startOf('day').startOf('month')
			const toDate = moment(maxDate).endOf('day').endOf('month')
			const fromMonday = fromDate.clone().startOf('isoWeek')
			const toSunday = toDate.clone().endOf('isoWeek')
			const count = toSunday.diff(fromMonday, 'days') + 1
			const offsetToday = todayDate.startOf('month').startOf('isoWeek').diff(fromDate, 'days')
			return { today, offsetToday, minDate, maxDate, fromDate, toDate, fromMonday, toSunday, count }
		},
		profileLookup() {
			const r = {}
			for (const profile of this.profiles ?? []) {
				r[profile.id] = profile
			}
			return r
		},
		calendar() {
			const days = []
			let d = -1
			for (let currentDay = this.focusStart.clone().startOf('isoWeek');
				currentDay <= this.focusEnd.clone().endOf('isoWeek');
				currentDay = currentDay.add(1, 'days')
			) {
				d++
				const day: { day?: any, year?: string, month?: string, dom?: string, [ i: string ]: string | boolean | number } = { day: currentDay.clone()}
				day.weekday = day.day.format('dddd').toLowerCase()
				day.date = day.day.format('YYYY-MM-DD')
				day.dateObject = day.day.toDate()
				day.year = day.day.format('YYYY')
				day.month = day.day.format('MM')
				day.dom = day.day.format('DD')
				day.inRange = day.date >= this.dates.minDate && day.date <= this.dates.maxDate
				day.open = false // TODO?
				day.selected = false // this.isSelected(day)
				const profileId = this.profileIds[day.year]?.[day.month]?.[day.dom] ?? 0
				day.profile = this.profileLookup[profileId]
				day.x = d % 7
				day.y = Math.floor(d / 7)
				days.push(day)
			}
			return days
		},
	},
	methods: {
		go(day) {
			this.$refs.calendar.go(day)
		},
		selectProfile(profile) {
			if (this.selectedProfile == profile) return
			this.selectedProfile = profile
			this.go(profile.start)
			this.profileFocus = null
		},
		paint(profile, days) {
			for (const day of days) {
				if (!this.profileIds[day.year]) this.profileIds[day.year] = {}
				if (!this.profileIds[day.year][day.month]) this.profileIds[day.year][day.month] = {}
				this.profileIds[day.year][day.month][day.dom] = profile.id
			}
		},
	},
	mounted() {
		this.go('today')
	},
}
</script>

<style scoped>
.ProfileColorCalendar { --sel: lightskyblue; --focus: #295dc7; }

.profile { border-radius: 5px; position: relative; }
.profile.selected { outline: 2px solid var(--sel); }
table th { border-bottom: 1px solid #ddd; }
table th,
table td { text-align: left; padding: 10px; }
table input { border: 1px solid #ddd; border-radius: 5px; padding: 3px 6px; }

.profile .details { display: flex; gap: 10px; flex-wrap: wrap; }
.profile .hint { background: var(--sel); padding: 0px 5px; position: absolute; bottom: calc(-0.5em - 3px); right: 10px; font-size: 11px; color: white; border-radius: 10px; }
</style>

<style>
.PCC .v-color-picker-preview__dot,
.PCC .v-color-picker-preview__eye-dropper { display: none; }
</style>