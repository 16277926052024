<template>
	<DialogV2
		ref="dialog" 
		:height="height"
		:width="width"
		:title="title"
		:cancelLabel="cancelLabel || $t('text.cancel')"
		:confirmLabel="confirmLabel || $t('text.confirm')"
		:onCancel="cancel"
		:onConfirm="confirm"
		:closeOnCancel="onCancel ? false : true"
		:closeOnConfirm="onConfirm ? false : true"
		:isValid="!error"
		:steps="steps"
		:disableConfirmButton="disableConfirmButton"
	>
		<template #title>
			<slot name="title" :model="model" :wrap="wrap" :updateModel="updateModel" />
		</template>
		<template #header-utility>
			<slot name="header-utility" :model="model" :wrap="wrap" :updateModel="updateModel" />
		</template>
		<template #sidebar v-if="$slots.sidebar">
			<slot name="sidebar" :model="model" :wrap="wrap" :updateModel="updateModel" />
		</template>
		<template #content>
			<slot name="content" :model="model" :wrap="wrap" :updateModel="updateModel" />
		</template>
		<template #buttons>
			<div class="error" v-if="error">
				{{ error }}
			</div>
		</template>
	</DialogV2>
</template>

<script lang="ts">
import DialogV2 from './DialogV2.vue'

export default {
	name: 'DataDialog',
	components: { DialogV2 },
	props: {
		modelValue: Object,
		title: String,
		height: String,
		width: String,
		validator: Function,
		cancelLabel: String,
		onCancel: Function,
		onConfirm: Function,
		confirmLabel: String,
		steps: Array,
		disableConfirmButton: Boolean,

		// if switched on, out-binding will use wrap instead of model
		// this is useful in cases where the integrator would have to use v-model="model" (which does not work)
		// in such cases use it like this:
		// <DataDialog v-model="wrap.model" :useWrap="true">
		//    <template #content="{ wrap }">
		//       <v-checkbox v-model="wrap.model" />
		//    </template>
		// </DataDialog>
		useWrap: Boolean,
	},
	data: () => ({
		model: null,
		error: null,
		wrap: { model: null },
	}),
	watch: {
		modelValue(n) {
			if (!n) return
			this.model = JSON.parse(JSON.stringify(n))
			this.wrap.model = this.model
		},
		model: {
			deep: true,
			handler(n) {
				this.error = this.validator?.(n) ?? null
			},
		},
	},
	methods: {
		updateModel() {
			if (this.useWrap)
				this.$emit('update:modelValue', this.wrap.model)
			else
				this.$emit('update:modelValue', this.model)
		},
		async cancel() {
			if (this.onCancel) {
				const r = await this.onCancel()
				if (r === false) return
			}
			this.$refs.dialog.close()
		},
		async confirm() {
			if (this.onConfirm) {
				const r = await this.onConfirm()
				if (r === false) return
			}
			this.$refs.dialog.close()
			this.updateModel()
		},
		open() {
			this.model = JSON.parse(JSON.stringify(this.modelValue))
			this.wrap.model = this.model
			this.$refs.dialog.open()
		},
	},
}
</script>

<style scoped>
.error { color: darkred; }
</style>