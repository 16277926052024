import { toRaw } from 'vue'
import { createStore } from 'vuex'
import localforage from 'localforage'

const db = localforage.createInstance({
	name: "MyServicesDB",
	driver: localforage.INDEXEDDB,
})

const dbSetItem = async (key, value) => {
	//console.log('DEBUG: dbSetItem', key, JSON.stringify(value))
	db.setItem(key, toRaw(value))
}

const store = createStore({
	state: {
		activeTranslation: null,
		selectedLocale: null,
		locales: [],
		serviceLocale: null,
		defaultLocale: null,
		userSpecificGerman: null,
		serviceComponents: null,
		selectedComponent: null,
		selectedServiceProvider: null,
		selectedProduct: null,
		selectedOrder: null,
		selectedFAQGroup: null,
		selectedClient: null,

		loggedInUser: null,
		clientId: null,
		timer: null,
		isImpersonation: null,
		impersonatedServiceProvider: {},
		impersonationStartTime: null,
		filter: null,
		selectedUser: null,
		selectedServiceType: null,
		selectedPackageType: null,
		userMessages: null,
		selectedRegionMapping: null,
		packageVersion: window.__PACKAGE_VERSION__ || '',
		featureBranch:  window.__FEATURE_BRANCH__ || '',
		coreConfig: null,
		ticketServiceData: null,
		searchString: '',
		offset: null,

		translations: null,
		spApplications: null,

		attributeSets: null,
		peakProducts: null,
		schema: null,

		deeplinkHash: null,
		allApplications: null,
		initialUrl: '',
		showAuditLogs: false,
		featureFlags: {},
		fieldModels: [],
	},
	mutations: {
		async setActiveTranslation(state, item) {
			state.activeTranslation = item;
			await dbSetItem('activeTranslation', state.activeTranslation);
		},
		async setSelectedLocale(state, item) {
			state.selectedLocale = item;
			await dbSetItem('selectedLocale', state.selectedLocale);
		},
		async setLocales(state, locales) {
			state.locales = locales
			await dbSetItem('locales', state.locales)
		},
		async setServiceLocale(state, serviceLocale) {
			state.serviceLocale = serviceLocale
			await dbSetItem('serviceLocale', state.serviceLocale)
		},
		async setDefaultLocale(state, defaultLocale) {
			state.defaultLocale = defaultLocale
			await dbSetItem('defaultLocale', state.defaultLocale)
		},
		async setUserSpecificGerman(state, item) {
			state.userSpecificGerman = item;
			await dbSetItem('userSpecificGerman', state.userSpecificGerman);
		},
		async setComponents(state, items) {
			state.serviceComponents = items;
			await dbSetItem('components', state.serviceComponents);
		},
		async setSelectedServiceProvider(state, item) {
			state.selectedServiceProvider = item;
			await dbSetItem('selectedServiceProvider', state.selectedServiceProvider);
		},
		async setImpersonatedServiceProvider(state, item) {
			state.impersonatedServiceProvider = item;
			await dbSetItem('impersonatedServiceProvider', state.impersonatedServiceProvider);
		},
		async setSelectedComponent(state, item) {
			state.selectedComponent = item;
			await dbSetItem('selectedComponent', state.selectedComponent);
		},
		async setSelectedProduct(state, item) {
			state.selectedProduct = item;
			await dbSetItem('selectedProduct', state.selectedProduct);
		},
		async setSelectedOrder(state, item) {
			state.selectedOrder = item;
			await dbSetItem('selectedOrder', state.selectedOrder);
		},
		async setSelectedFAQGroup(state, item) {
			state.selectedFAQGroup = item;
			await dbSetItem('selectedFAQGroup', state.selectedFAQGroup);
		},
		async setSelectedClient(state, item) {
			state.selectedClient = item;
			await dbSetItem('selectedClient', state.selectedClient);
		},
		async setLoggedInUser(state, item) {
			state.loggedInUser = item;
			await dbSetItem('loggedInUser', state.loggedInUser);
		},
		async setClientId(state, item) {
			state.clientId = item;
			await dbSetItem('clientId', state.clientId);
		},
		async setTimer(state, item) {
			state.timer = item;
			await dbSetItem('timer', state.timer);
		},
		async setIsImpersonation(state, item) {
			state.isImpersonation = item;
			await dbSetItem('isImpersonation', state.isImpersonation);
		},
		async setImpersonationStartTime(state, item) {
			state.impersonationStartTime = item;
			await dbSetItem('impersonationStartTime', state.impersonationStartTime);
		},
		async setIsLinkedProvider(state, item) {
			state.isLinkedProvider = item;
			await dbSetItem('isLinkedProvider', state.isLinkedProvider);
		},
		async setFilter(state, item) {
			state.filter = item;
			await dbSetItem('filter', state.filter);
		},
		async setSelectedUser(state, item) {
			state.selectedUser = item;
			await dbSetItem('selectedUser', state.selectedUser);
		},
		async setSelectedServiceType(state, item) {
			state.selectedServiceType = item;
			await dbSetItem('selectedServiceType', state.selectedServiceType);
		},
		async setSelectedPackageType(state, item) {
			state.selectedPackageType = item;
			await dbSetItem('selectedPackageType', state.selectedPackageType);
		},
		async setUserMessages(state, items) {
			state.userMessages = items;
			await dbSetItem('userMessages', state.userMessages);
		},
		async setSelectedRegionMapping(state, item) {
			state.selectedRegionMapping = item
			await dbSetItem('selectedRegionMapping', state.selectedRegionMapping);
		},
		async setCoreConfig(state, item) {
			state.coreConfig = item
			await dbSetItem('coreConfig', state.coreConfig);
		},
		async setTicketServiceData(state, item) {
			state.ticketServiceData = item
			await dbSetItem('ticketServiceData', state.ticketServiceData);
		},
		async setSearchString(state, item) {
			state.searchString = item
			await dbSetItem('searchString', state.searchString);
		},
		async setOffset(state, item) {
			state.offset = item
			await dbSetItem('offset', state.offset)
		},
		async setTranslations(state, item) {
			state.translations = item
			await dbSetItem('translations', state.translations);
		},
		async setSPApplications(state, item) {
			state.spApplications = item
			await dbSetItem('spApplications', state.spApplications);
		},
		async setAttributeSets(state, item) {
			state.attributeSets = item
			await dbSetItem('attributeSets', state.attributeSets);
		},
		async setPeakProducts(state, item) {
			state.peakProducts = item
			await dbSetItem('peakProducts', state.peakProducts);
		},
		async setSchema(state, item) {
			state.schema = item
			await dbSetItem('schema', state.schema);
		},
		async setDeeplinkHash(state, item) {
			state.deeplinkHash = item
			await dbSetItem('deeplinkHash', state.deeplinkHash);
		},
		async setAllApplications(state, item) {
			state.allApplications = item
			await dbSetItem('allApplications', state.allApplications);
		},
		async setInitialUrl(state, item) {
			state.initialUrl = item
			await dbSetItem('initialUrl', state.initialUrl);
		},
		async clear() {
			// we preserve some data, because otherwise the ui is messed up
			const translations = store.state.translations
			await db.clear()
			await store.commit('setTranslations', translations)
		},
		// TEMP: flag to set audit log visibility on/off (for testing in trunk)
		async setAuditLogFlag(state, item) {
			state.showAuditLogs = item;
			await dbSetItem('showAuditLogs', state.showAuditLogs);
		},
		async setFeatureFlags(state, item) {
			state.featureFlags = item;
			await dbSetItem('featureFlags', state.featureFlags);
		},
		async setFieldModels(state, item) {
			state.fieldModels = item
			await dbSetItem('fieldModels', state.fieldModels)
		},
	},
	actions: {
		getItem(itemName) {
			return this.state[itemName]
		}
	},
	getters: {
		appVersion: (state) => {
			return state.packageVersion
		},
		featureBranch: (state) => {
			return state.featureBranch
		}
	}
})

store.init = async () => {
	if (db) {
		store.state.activeTranslation = await db.getItem('activeTranslation') ?? 'de'
		store.state.selectedLocale = await db.getItem('selectedLocale') ?? 'de'
		store.state.locales = await db.getItem('locales') ?? []
		store.state.serviceLocale = await db.getItem('serviceLocale') ?? 'de'
		store.state.userSpecificGerman = await db.getItem('userSpecificGerman') ?? 'de'
		store.state.serviceComponents = await db.getItem('components')
		store.state.selectedComponent = await db.getItem('selectedComponent')
		store.state.selectedServiceProvider = await db.getItem('selectedServiceProvider')
		store.state.selectedProduct = await db.getItem('selectedProduct')
		store.state.selectedOrder = await db.getItem('selectedOrder')
		store.state.selectedFAQGroup = await db.getItem('selectedFAQGroup')
		store.state.selectedClient = await db.getItem('selectedClient')
		store.state.loggedInUser = await db.getItem('loggedInUser')
		store.state.clientId = await db.getItem('clientId')
		// TODO: this is only used in ComponentCard for order customizing which IMO is not being used any longer
		store.state.timer = await db.getItem('timer')
		store.state.isImpersonation = await db.getItem('isImpersonation') ?? false
		store.state.impersonationStartTime = await db.getItem('impersonationStartTime')
		store.state.isLinkedProvider = await db.getItem('isLinkedProvider') ?? false
		store.state.impersonatedServiceProvider = await db.getItem('impersonatedServiceProvider')
		store.state.filter = await db.getItem('filter')
		store.state.selectedUser = await db.getItem('selectedUser')
		store.state.selectedServiceType = await db.getItem('selectedServiceType')
		store.state.selectedPackageType = await db.getItem('selectedPackageType')
		store.state.userMessages = await db.getItem('userMessages')
		store.state.selectedRegionMapping = await db.getItem('selectedRegionMapping')
		store.state.coreConfig = await db.getItem('coreConfig')
		store.state.ticketServiceData = await db.getItem('ticketServiceData')
		store.state.searchString = await db.getItem('searchString')
		store.state.offset = await db.getItem('offset')
		store.state.translations = await db.getItem('translations')
		store.state.spApplications = await db.getItem('spApplications')
		store.state.attributeSets = await db.getItem('attributeSets')
		store.state.peakProducts = await db.getItem('peakProducts')
		store.state.schema = await db.getItem('schema')
		store.state.deeplinkHash = await db.getItem('deeplinkHash')
		store.state.allApplications = await db.getItem('allApplications')
		store.state.initialUrl = await db.getItem('initialUrl')
		store.state.showAuditLogs = await db.getItem('showAuditLogs') ?? false
		store.state.featureFlags = await db.getItem('featureFlags') ?? {}
		store.state.fieldModels = await db.getItem('fieldModels') ?? []
	}	
}

export default store
