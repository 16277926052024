<template>
	<div class="NumberField" :class="{ error }">
		<input type="number" class="input" v-model="model" @focus="onFocus" @blur="onBlur" :min="validations.range?.min" :max="validations.range?.max" :data-cy="dataCy" />
		<div v-if="unit" class="unit">{{ unit }}</div>
	</div>
</template>

<script>
import { field } from './FieldMixin.js'

export default {
	name: 'NumberField',
	mixins: [ field ],
	props: {
		modelValue: [ Number, String ],
		dataCy: String,
	},
	data: () => ({
		model: null,
	}),
	computed: {
		unit() {
			const unit = this.field?.control?.settings?.unit
			if (!unit) return null
			// for cases where we want to use '%' and other primitive things as units
			// we dont want to create translation strings, so we use the plain string
			const key = 'text.unit-' + unit
			const r = this.$t(key)
			if (r == key) return unit
			return r
		},
	},
	watch: {
		modelValue(n) {
			this.model = n
			this.validate()
		},
		model(n) {
			this.$emit('update:modelValue', Number(n))
		},
	},
	methods: {
		validate() {
			this.onErrors([
				this.validateRequired(),
				this.validateMax(),
				this.validateMin(),
				this.validateIn(),
			])
		},
	},
	mounted() {
		this.model = this.modelValue
		this.validate()
	},
}
</script>

<style scoped>
.NumberField { position: relative; max-width: 100px; }
.unit { position: absolute; top: 8px; right: 8px; color: #333; pointer-events: none; }
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button { -webkit-appearance: none; margin: 0; }
input[type=number] { -moz-appearance: textfield; }
</style>