<!-- TEST: 
1. on command line
	curl --location --request GET 'http://localhost:8080/api/business-hours/token?email=test@goback3.test' --header 'Authorization: Basic bXlzX2J1c2luZXNzaG91cnM6YUExNjEzVyRrOE9E'
	-> this will give a token
2. open url
	http://localhost:8080/businessHours?token={TOKEN}
-->
<template>
	<Application :errorDetail="errorDetail" v-model:errorTitle="errorTitle" :successDetail="successDetail" v-model:successTitle="successTitle" :loading="loading">
		<template #navbar>
			<h1 style="line-height: 100%; font-size: 18px; font-weight: bold;">{{ $t('text.updateBusinessHours') }}</h1>
		</template>

		<div v-if="tokenIsValid" class="pa-0 mt-10" style="width: 100%; max-width: 700px; margin: auto;">
			<span id="businessInfo"/><br/>
			<BusinessHoursNew
				ref="businessInfo"
				:showCopyOption="false" 
				:businessHours="businessHours" 
				:averageDurationOfStayInMinutes="averageDurationOfStayInMinutes"
				app="BusinessProfile"
				:expanded="true"
				@toggle-business-hours="toggleHaveBusinessHours" 
				@average-duration-changed="averageDurationChanged"
			/>
<!--			<BusinessHours
				v-if="tokenIsValid"
				ref="businessInfo"
				:showCopyOption="false" 
				:businessHours="businessHours" 
				:averageDurationOfStayInMinutes="averageDurationOfStayInMinutes"
				app="BusinessProfile"
				:expanded="true"
				@toggle-business-hours="toggleHaveBusinessHours" 
				@average-duration-changed="averageDurationChanged"
			/>-->
			<Disclosure :title="$t('text.brazeInfo')" :lock="!userMayEditFields" data-cy="brazeInfo" ref="SECTION_brazeInfo">
				<v-label>{{$t('text.mobileNumber', { format: phoneFormat})}}</v-label>
					<v-text-field variant="outlined" density="compact" required
						v-model="mobileNumber"
						placeholder=""
						hide-details
				/>
			</Disclosure>
			<v-btn
				style="float: right; margin-bottom: 15%; margin-top: 10px;"
				class="blueButton btn-spacing" elevation="0" dark 
				v-if="tokenIsValid"
				@click="updateBusinessHours"
			>
				{{ $t('text.updateBusinessHours') }}
			</v-btn>
		</div>
	</Application>
</template>

<script>
import BusinessHours from '@/components/businesshours/BusinessHours.vue'
import Common from '@/mixins/Common.vue'
import Alert from '@/components/common/Alert.vue'
import Loading from 'vue-loading-overlay'
import Application from '../../views/applications/Application.vue'
import BusinessHoursNew from './BusinessHoursNew.vue'
import Disclosure from '@/components/common/Disclosure.vue'

export default {
	name: 'BusinessHoursStandalone',
	components: { BusinessHours, Alert, Loading, Application, BusinessHoursNew, Disclosure },
	mixins: [ Common ],
	props: {
		token: {
			type: String,
			required: true
		},
	},
	data: () => ({
		businessHours: [],
		averageDurationOfStayInMinutes: 0,
		mobileNumber: '',
		tokenIsValid: false,
		spId: null,
		loading: false,
	}),
	methods: {
		async getBusinessHoursByToken() {
			this.loading = true
			try {
				let res = await this.$httpGet(`/business-hours/token/${ this.token }`)
				this.setData(res.response)
			}
			catch (error) {
				this.showError(error.message)
				console.error('BH standalone getBusinessHoursByToken error', error)
				setTimeout(() => {
					this.$emit('show-login')
				}, 5000)
			}
			this.loading = false
		},
		setData(data) {
			this.businessHours = data.businessHours
			this.averageDurationOfStayInMinutes = data.averageDurationOfStayInMinutes
			this.mobileNumber = data.mobileNumber
			this.spId = data.serviceProviderId
			this.tokenIsValid = true
		},
		averageDurationChanged(value) {
			this.averageDurationOfStayInMinutes = parseInt(value)
		},
		toggleHaveBusinessHours(event) {
			if (!event || event === false) {
				this.businessHours = []
			} else {
				this.businessHours = [this.$refs.businessInfo.getNewBusinessHours(false)]
			}
		},
		async updateBusinessHours() {
			this.loading = true
			try {
				const payload = {
					businessHoursData: this.$refs['businessInfo']?.sendData(),
					averageDurationOfStayInMinutes: this.averageDurationOfStayInMinutes,
					mobileNumber: this.mobileNumber,
					token: this.token
				}
			
				await this.$httpPut(`/business-hours`, payload)
				this.showSuccess()
			}
			catch (error) {
				this.showError(error.message)
				console.error('BH standalone updateBusinessHours error', error)
				if (error.response?.status === 401) {
					setTimeout(() => {
						this.$emit('show-login')
					}, 5000)
				}
			}
			this.loading = false
		},
		showSuccess() {
			this.successTitle = this.$t('text.SUCCESS')
			this.successDetail = this.$t('text.businessHoursUpdatedDetail')
		},
	},
	async mounted() {
		this.$emit('hide-navbar')
		await this.getBusinessHoursByToken()
	},
}
</script>

<style scoped>
.title {
	margin-bottom: 15px;
	text-align: center;
	border: 1px solid #ccc;
	border-radius: 5px;
	padding: 5px;
	background-color: #f3f3f3;
}
</style>