<template>
	<div class="TextField" :class="{ error }">
		<input type="text" class="input" v-model="model" @focus="onFocus" @blur="onBlur" @input="updateModelValue" ref="input" :data-cy="dataCy" :disabled="disabled" />
		<FieldConstraintsInfo :value="model" :error="error" :min="minSize" :max="maxSize" />
	</div>
</template>

<script lang="ts">
// TODO: Unique field - check if any other entry with same value
import { field } from './FieldMixin.js'
import FieldConstraintsInfo from './FieldConstraintsInfo.vue'

export default {
	components: { FieldConstraintsInfo },
	mixins: [ field ],
	props: {
		modelValue: [ Number, String ],
		type: String,
		field: Object,
		disabled: Boolean,
		dataCy: String,
	},
	data: () => ({
		model: null,
	}),
	// TODO: convert validations structure into something more workable - where?
	computed: {
		minSize() {
			return this.validations.size?.min
		},
		maxSize() {
			if (this.field.type == 'Symbol')
				return this.validations.size?.max ?? 255
			return this.validations.size?.max ?? 50000
		},
	},
	watch: {
		modelValue(value) {
			this.model = value
			this.validate()
		},
	},
	methods: {
		updateModelValue({ target: { value } }) {
			this.$emit('update:modelValue', value)
		},
		validate() {
			this.onErrors([
				this.validateRequired(),
				this.validateMax(),
				this.validateMin(),
				this.validateRegexp(),
				this.validateProhibitRegexp(),
				this.validateIn(),
			])
		},
	},
	mounted() {
		this.model = this.modelValue
		this.validate()
	},
}
</script>
