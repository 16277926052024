<template>
	<div class="TagsField">
		<v-combobox
			v-model="current"
			:items="allowedValues"
			@keydown.enter="addTag"
			placeHolder="Type the value and hit enter"
			variant="outlined"
			v-if="!allowedValues && !validations.inLinks"
			:data-cy="dataCy"
		>
			<template v-slot:item="{ item }">
				<v-chip>{{ item }}</v-chip>
			</template>
		</v-combobox>

		<v-combobox
			v-model="current"
			:items="allowedValues"
			placeHolder="Type the value and hit enter"
			variant="outlined"
			@keydown.enter="addTag"
			v-if="allowedValues"
			:data-cy="dataCy"
		>
			<template v-slot:item="{ item }">
				<v-chip>{{ item }}</v-chip>
			</template>
		</v-combobox>

		<v-combobox
			v-model="current"
			:items="validations.inLinks"
			placeHolder="Type the value and hit enter"
			variant="outlined"
			@keydown.enter="addTag"
			v-if="validations.inLinks"
			:data-cy="dataCy"
		>
			<template v-slot:item="{ item }">
				<v-chip>{{ item.sys.id }}</v-chip>
			</template>
		</v-combobox>

		<div class="tags" v-if="modelValue">
			<v-chip
				v-for="(val, v) of modelValue"
				:key="val?.sys?.id ?? val"
				@click="deleteAt(v, val)"
				class="tag"
			>
				{{ val?.sys?.id ?? val }}
				<v-icon @click.stop="deleteAt(v, val)">mdi-close</v-icon>
			</v-chip>
		</div>

		<div v-if="validationError" class="fieldError">
			{{ validationError }}
		</div>
		<div v-if="current" class="fieldWarning">
			You have entered content that will not be saved. Press enter to add the tag!
		</div>
	</div>
</template>

<script>
import { field } from './FieldMixin.js'

export default {
	name: 'TagsField',
	mixins: [field],
	props: {
		locale: String,
		modelValue: Array,
		def: Object,
		options: Array,
		dataCy: String,
	},
	data: () => ({
		current: '',
	}),
	computed: {
		// TODO: reimplement validation
		// TODO: validation: in, size min/max, pattern, ...
		// TODO: inLinks is similar to in. we need to also support this mode in the code below
		allowedValues() {
			return this.options ?? this.def?.items?.validations?.[0]?.in
		},
		validations() {
			return {
				inLinks: this.def?.items?.validations?.[0]?.inLinks,
			}
		},
		validationError() {
			if (
				this.current !== '' &&
				this.current != null &&
				this.allowedValues &&
				this.allowedValues.indexOf(this.current) < 0
			) {
				return 'Value must be one of: ' + this.allowedValues.join(', ')
			}

			return null
		},
	},
	methods: {
		addTag() {
			if (this.validationError) return
			this.modelValue.push(this.current)
			this.current = ''
		},
		deleteAt(v, val) {
			this.modelValue.splice(v, 1)
		},
		validate() {
			this.onErrors([
				this.validateRequired(),
				this.validateArrayMax(),
				this.validateArrayMin(),
				// TODO: how should the regex be validated? do we need an "arrayRegex"?
				//       probably for each item of the array
				//this.validateRegexp(),
				//this.validateProhibitRegexp(),
				this.validateArrayIn(),
				this.validateArrayInLinks(),
			])
		},
	},
	mounted() {
		// TODO: this component is directly modifying the modelValue. this is not good. we should emit an event instead. For instance, in dialogs this can create a problem because when the dialog is cancelled, the modelValue is already modified. It only needs to be persisted to the parent model when the dialog is confirmed.
		this.$nextTick(() => {
			if (!this.modelValue) {
				this.$emit('update:modelValue', [])
			}
		})
		this.validate()
	},
}
</script>

<style scoped>
.TagsField {
	position: relative;
}
.tags {
	font-size: 13px;
}
.tag {
	--padding: 3px;
	position: relative;
	background: rgb(231, 235, 238);
	border-radius: 4px;
	padding: var(--padding);
	margin-bottom: 5px;
	margin-right: 5px;
	color: rgb(65, 77, 99);
	display: inline-flex;
}
.tag svg {
	font-size: 18px;
	fill: currentColor;
}
.tag .close {
	border-left: 1px solid silver;
	margin: calc(-1 * var(--padding))
		calc(-1 * var(--padding))
		calc(-1 * var(--padding))
		var(--padding);
	padding: var(--padding);
	cursor: pointer;
}
.tag .close:hover {
	background-color: rgb(207, 217, 224);
}

.fieldWarning {
	color: darkorange;
}
.fieldError {
	color: red;
}
</style>