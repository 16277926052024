<template>
	<div class="AddedValue">
		<DisclosureItemsDialog v-model="model" ref="dialog">
			<template #itemHead="{ item, expanded }">
				<div class="sectionTitle">
					<div class="expandIcon">
						<v-icon :icon="expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'" />
						{{ item.fields.name[serviceLocale] }}
					</div>
					<div class="sectionTitleEnd">
						<AddedValueType @customizeOptions="$refs.optionalSettingsDialog?.open?.()" />
					</div>
				</div>
			</template>

			<template #itemBody="{ item }" >
				<div class="optional-items">
					<FieldSet id="generalSettings">
						TODO: where do we get the variants from?
						this is still an ongoing discussion in Product Management
						we may need some aggregation logic at the server
						OR: we may need to "group" the products into configurable products before starting this dialog
						<!-- <Field typeName="AddedValue" fieldName="productVariants" v-model="model.fields.productVariants" /> -->
					</FieldSet>

					<div>
						<Optional id="reductionSettings" v-model="optionalSettings" startEnabled
						:autoShow="item.fields.reductionPercent?.de > 0" @disable="model.fields.reductionPercent.de = 0;">
							<FieldSet id="reductionSettings">
								<Field typeName="AddedValue" fieldName="reductionPercent" v-model="item.fields.reductionPercent" required />
							</FieldSet>
						</Optional>

						<Optional id="seasonalDiscounts" v-model="optionalSettings" startEnabled
						:autoShow="item.fields.seasonalDiscounts?.de?.length > 0" @disable="item.fields.seasonalDiscounts.de.length = 0;">
							<FieldSet id="seasonalDiscounts" class="pt-6">
								<Table
									class="mt-4"
									:columns="[ 'startDate', 'endDate', 'reductionPercent' ]"
									typeId="SeasonalDiscount"
									:editable="true"
									v-model="item.fields.seasonalDiscounts.de"
									@create="item.fields.seasonalDiscounts.de.push($event)"
								/>
							</FieldSet>
						</Optional>
					</div>
					<div class="todo">https://app.zeplin.io/project/604748c2bca41a3e66787ef4/screen/647daa0944b5cc2605fe228e</div>
						<FieldSet id="contingentSettings" >
							<Field typeName="AddedValue" fieldName="maximumRedemptionCount" v-model="item.fields.maximumRedemptionCount" />
						</FieldSet>
						<!-- TODO: this should actually be a field -->
						<FieldSet id="validitySettings" >
							<Field typeName="AddedValue" fieldName="validityType" v-model="item.fields.validityType" />
							<div style="display: flex; gap: 10px;" :class="{
								disabled: item.fields.validityType?.de != 'more',
							}">
								<Field typeName="AddedValue" fieldName="validDaysBefore" v-model="item.fields.validDaysBefore" style="flex: 1;" />
								<Field typeName="AddedValue" fieldName="validDaysAfter" v-model="item.fields.validDaysAfter" style="flex: 1;" />
							</div>
						</FieldSet>
						<FieldSet id="additionalSettings" >
							<Field typeName="AddedValue" fieldName="showPrice" v-model="item.fields.showPrice" />
						</FieldSet>
					</div>
			</template>
		</DisclosureItemsDialog>

		<!-- Optional Settings Dialog -->
		<DialogV2 :title="$t('text.customizeOptions')" ref="optionalSettingsDialog">
			<template #content>
				<OptionalSettings v-model="optionalSettings" @update:modelValue="optionalSettings = $event" :items="optionalSettingsItems" />
			</template>
		</DialogV2>
	</div>
</template>

<script>
import Field from '../../../components/fields/Field.vue'
import FieldSet from './FieldSet.vue'
import AddedValueType from './AddedValueType.vue'
import Table from './Table.vue'
import Optional from './Optional.vue'
import OptionalSettings from './OptionalSettings.vue'
import DialogV2 from '@/components/common/DialogV2.vue'
import DisclosureItemsDialog from './DisclosureItemsDialog.vue'
import Common from '@/mixins/Common.vue'

export default {
	components: { FieldSet, Field, AddedValueType, Table, Optional, OptionalSettings, DialogV2, DisclosureItemsDialog},
	mixins: [Common],
	props: {
		modelValue: {
			type: Array,
			required: true,
		},
		selected: {
			type: Array,
			required: true,
		},
	},
	data: () => ({
		expand: true,
		model: [ {
			sys: { id: '123'},
			fields: {
				name: { de: 'Added value 1' },
				reductionPercent: { de: 0 },
				seasonalDiscounts: { de: [] },
				maximumRedemptionCount: { de: 1 },
				validityType: { de: 'stay' },
				// TODO: test what happend when an attr is undefined
				//       i think its currently not very graceful..
				validDaysBefore: { de: 0 },
				validDaysAfter: { de: 0 },
				showPrice: { de: true },
			}
			},
			{
				sys: { id: '456'},
				fields: {
					name: { de: 'Added value 2' },
					reductionPercent: { de: 0 },
					seasonalDiscounts: { de: [] },
					maximumRedemptionCount: { de: 1 },
					validityType: { de: 'stay' },
					validDaysBefore: { de: 0 },
					validDaysAfter: { de: 0 },
					showPrice: { de: true },
				}
			}
		],
		seasonalDiscounts: [
			{ fields: { startDate: { de: '' }, endDate: { de: '' }, reductionPercent: { de: 0 } } },
		],
		optionalSettings: {},
	}),
	watch: {
		model(n) { this.$emit('update:modelValue', n) },
		modelValue(n) { this.model = n },
	},
	computed: {
		optionalSettingsItems() {
			return [
				{ id: 'reductionSettings', items: ['reductionPercentageInput', 'seasonalDiscounts'] },
				{ id: 'contingentSettings' },
				{ id: 'validitySettings' },
				{ id: 'additionalSettings', items: ['showPrice'] },
			]
		},
	},
	methods: {
		toggleExpand() {
			this.expand = !this.expand
		},
		async getAddedValueDetails(id) {
			const addedValue = await this.$store.dispatch('contentful/getEntry', id)
			return addedValue
		},
	},
	async mounted() {
		// TODO: get the product details for every id in the selected array??
		// this.selected.forEach(async (id) => {
		// 	const addedValue = await this.getAddedValueDetails(id)
		// 	this.model.push(addedValue)
		// })
		// TODO: connect with the model of the packageDetails
		// this.model = this.modelValue
		console.log('model in addedValue2', this.model)
		this.$refs.dialog.open()
	},
}
</script>

<style scoped>
.AddedValue {
	border: 1px solid #ddd;
	border-radius: 5px;
	overflow: hidden;
}

.header {
	cursor: pointer;
	display: flex;
	gap: 10px;
	align-items: center;
	padding: 10px;
	background-color: #F4F4F4;
	border-bottom: 1px solid #ddd;
}

.header .type {
	font-size: smaller;
	color: gray;
}

.body {
	background: white;
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 10px 30px;
}

.disabled {
	opacity: 0.5;
	pointer-events: none;
}

h2 {
	font-size: 14px;
	margin: 0;
	padding: 0;
	line-height: initial;
}

.expandIcon {
	display: flex;
	align-items: center;
	justify-content: left;
	width: calc(18vw + 400px);
	height: 24px;
	border-radius: 50%;
	background-color: #f4f4f4;
}

.sectionTitle {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 24px;
	font-size: 14px;
	font-weight: bold;
}

@media (min-width: 1900px) {
	.sectionTitle {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 80%;
		height: 24px;
	}
}

.sectionTitleEnd {
	display: flex;
	align-items: center;
	justify-content: end;
	width: 30%;
	height: 24px;
}
</style>

<style lang="scss">
.v-expansion-panel::after {
	border-top-style: none !important;
}
</style>
