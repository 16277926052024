<template>
	<v-dialog v-model="isOpen" :scrollable="true" transition="dialog-bottom-transition" :persistent="!closeOnOuterClick">
		<div v-if="isOpen">
			<div class="dialog-div" :style="{ height, width }">
				<loading :active="loading" :is-full-page="true" color="#4caf50" data-cy="loading" />
				<v-toolbar class="dialog-header">
					<div
						style="position: absolute; inset: 0; display: flex; gap: 10px; align-items: center; padding: 10px 32px; border-bottom: 1px solid #ddd;">
						<div style="flex-grow: 1; display: flex; flex-direction: column;">
							<slot name="title">
								<v-toolbar-title style="">
									<span>{{ title }}</span>
								</v-toolbar-title>
							</slot>
						</div>

						<div style="flex-grow: 1;" class="d-none d-md-block">
							<StepperV2 v-if="steps?.length" :steps="steps" :modelValue="currentStep" @update:step="updateStep" />
						</div>

						<v-btn v-if="!hideClose" class="none" style="position: relative; right: -20px; top: -3px;"
							@click="close()">
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</div>
				</v-toolbar>

				<slot name="header-utility"></slot>
				
				<v-layout row wrap class="scrollable dialog-content">
					<v-col class="content pa-0 overflow-x-auto">

						<div v-if="$slots.sidebar" class="sidebar">
							<slot name="sidebar"></slot>
						</div>
						<div :class="{ 'content-with-sidebar': $slots.sidebar, 'content-wrapper': true }">
							<slot name="content"></slot>
						</div>
					</v-col>
				</v-layout>

				<v-toolbar class="dialog-footer">
					<div
						style="position: absolute; inset: 0; display: flex; gap: 10px; align-items: center; padding: 10px 32px; border-top: 1px solid #ddd;">
						<v-spacer />
						<slot name="buttons"></slot>
						<v-btn class="defaultButton gradientButton" theme="dark" elevation="0" data-cy="cancel"
							@click="handleCancel">
							{{ cancelLabel ?? $t('text.cancel') }}
						</v-btn>
						<v-btn v-if="deleteLabel" class="redButton" theme="dark" elevation="0" data-cy="delete"
							@click="handleRemove">
							<v-icon size="24px" color="white">mdi-delete</v-icon>
							{{ deleteLabel }}
						</v-btn>
						<v-btn :class="isDelete ? 'redButton' : 'greenButton'" theme="dark" elevation="0" data-cy="confirm"
							@click="handleConfirm" :disabled="disableConfirmButton">
							{{ confirmLabel ?? $t('text.confirm') }}
						</v-btn>
					</div>
				</v-toolbar>
			</div>
		</div>
	</v-dialog>
</template>

<script>
import Loading from 'vue-loading-overlay'
import StepperV2 from '@/components/stepper/StepperV2.vue'

export default {
	name: 'DialogV2',
	components: { StepperV2, Loading },
	props: {
		title: String,
		confirmLabel: String,
		cancelLabel: String,
		deleteLabel: String,
		onConfirm: Function,
		onCancel: Function,
		onDelete: Function,
		hideBackdrop: Boolean,
		closeOnOuterClick: {
			Boolean,
			default: true,
		},
		hideClose: Boolean,
		disableConfirmButton: Boolean,
		width: { type: String, default: '1100px' },
		height: String,
		isDelete: Boolean,
		showSidebar: Boolean,
		steps: Array,
		currentStep: Number,
		fixIndexes: { type: Boolean, default: true },
		closeOnConfirm: { type: Boolean, default: true },
		closeOnCancel: { type: Boolean, default: true },
		loading: { type: Boolean, default: false },
	},
	data() {
		return {
			isOpen: false,
		}
	},
	watch: {
		isOpen(n) {
			if (this.fixIndexes) this.fixZindexes(n)
		}
	},
	methods: {
		open() {
			this.isOpen = true
		},
		close() {
			this.isOpen = false
		},
		async handleConfirm() {
			await this.onConfirm?.()

			if (!this.closeOnConfirm) return

			this.close()
		},
		handleCancel() {
			this.onCancel?.()

			if (!this.closeOnCancel) return

			this.close()
		},
		handleRemove() {
			this.onDelete?.()
			this.close()
		},
		updateStep(step) {
			this.$emit('update:step', step)
		},
		fixZindexes(n) {
			// raise/restore the whole parent stack's z-index to avoid overlaying siblings
			for (let el = this.$parent; el; el = el.$parent) {
				if (!el?.$el?.style) continue
				if (n) {
					el.$el.style['z-index-backedup'] = true
					el.$el.style['z-index-backup'] = el.$el.style['z-index']
					el.$el.style['z-index'] = 999
				}
				else {
					if (el.$el.style?.['z-index-backedup'])
						el.$el.style['z-index'] = 1 //el.$el.style['z-index-backup']
				}
			}
		}
	},
	beforeUnmount() {
		if (this.fixIndexes) this.fixZindexes(false)
	},

}
</script>

<style scoped lang="scss">
.dialog-div {
	position: fixed;
	display: flex;
	flex-direction: column;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 1100px;
	height: min(700px, calc(100vh - 20px));
	max-width: calc(100vw - 20px);

	background-color: #ffffff;
	border-radius: 5px !important;
	box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.2);
	z-index: 999999 !important;
	overflow: hidden;

	i {
		margin-right: 5px;
	}

	.dialog-header {
		max-height: 72px;
	}

	.dialog-footer {
		.gradientButton {
			color: #000;
			max-width: 190px;
		}

		.greenButton {
			max-width: 190px;
		}

		.redButton {
			max-width: 190px;
		}
	}
}

.scrollable {
	overflow-y: auto;
	overflow-x: hidden;
}

.sidebar {
	position: fixed;
	top: 64px;
	bottom: 64px;
	width: 240px;
	background-color: #f4f4f4;
	border-right: 1px solid rgba(0, 0, 0, 0.1);
	overflow: auto;
}

.content-with-sidebar {
	margin-left: 240px;
}

.content-wrapper {
	padding: 32px;
}

.step-bar {
	border: none;
}
// overwrites the default vuetify dialog overlay so it's NOT transparent
.v-overlay {
	background: rgba(0, 0, 0, 0.5);
}
</style>
