<template>
	<div class="PackageSalesChannels Field" v-if="salesChannels && model" data-cy="package-sales-channels-field">
		<!-- TODO: tag display field: show each channel (should probably look different from the market place display in the SD -->
		<div @click="$refs.editDialog.open()" class="input">
			<v-chip v-for="scp of scps" :key="'sc-scp' + scp.salesChannel.id" :class="{ error: scp.error }">
				{{ scp.salesChannel.name }} ({{ scp.products.length }} Products)
			</v-chip>
		</div>
		<!--
			@update:modelValue="editComplete"
			:validator="model => model.fields.validFromDate.de >= model.fields.validToDate.de ? $t('text.fromDateAfterToDateError') : null"
		-->
		<DataDialog ref="editDialog"
			v-model="model.fields.websites.de"
			:title="$t('text.salesChannels')"
			:useWrap="true"
		>
			<template #content="{ wrap }">
				<!-- TODO: https://app.zeplin.io/project/604748c2bca41a3e66787ef4/screen/659fed1da16c1ec72109ce93 -->
				<div v-for="(scp, s) of salesChannelProductsLookup" :key="'scp' + s"
					style="display: flex; gap: 10px; align-items: center;"
				>
					<v-checkbox v-model="wrap.model" hide-details 
						:label="scp.salesChannel.name" 
						:value="{ id: scp.salesChannel.id, name: scp.salesChannel.name }" 
						data-cy="sales-channel-checkbox"
						/>
					{{ scp.products.length }} Products
				</div>
			</template>
		</DataDialog>
	</div>
</template>

<script>
import DataDialog from '../../../components/common/DataDialog.vue'
import ChildErrorDispatcher from './ChildErrorDispatcher.vue'

export default {
	mixins: [ ChildErrorDispatcher ],
	components: { DataDialog },
	props: {
		// TODO: the modelValue here is the whole package, should only be the websites!
		modelValue: Object,
		salesChannels: Array,
		products: Array,
		required: { type: Boolean, default: true },
	},
	data: () => ({
		model: null,
	}),
	computed: {
		salesChannelProductsLookup() {
			if (!this.products) return {}
			const r = {}
			if (!this.salesChannels) return r
			for (const salesChannel of this.salesChannels) {
				const products = this.products.filter(p => p.product_websites.some(website => website.product_website_id === salesChannel.id))
				r[salesChannel.id] = { salesChannel, products, error: !products.length }
			}
			for (const sc of this.modelValue.fields.websites.de) {
				if (!r[sc.id]) r[sc.id] = { salesChannel: sc, products: [], error: true }
			}
			return r
		},
		scps() {
			if (!this.products) return []
			else {
				const r = []
				for (const sc of this.modelValue.fields.websites.de) {
					let scp = this.salesChannelProductsLookup[sc.id]
					// TODO: should we maintain these wrong selections?
					//if (!scp) scp = { salesChannel: sc, products: [], error: true } //continue
					r.push(scp)
				}
				return r
			}
		},
	},
	watch: {
		modelValue: {
			deep: true,
			handler(n) {
				this.model = n
				this.validate()
			},
		},
		model: {
			deep: true,
			handler(n) {
				this.$emit('update:modelValue', n)
			},
		},
	},
	methods: {
		validate() {
			this.$nextTick(() => {
				if (!this.modelValue?.fields?.websites?.de?.length)
					this.childErrorAutoDispatch(this.$el, [ { id: 'noSalesChannels' } ])
				else
					this.childErrorAutoDispatch(this.$el, [])
			})
		},
	},
	mounted() {
		this.model = this.modelValue
		this.validate()
	},
}
</script>

<style scoped>
.input { cursor: pointer; height: auto; max-height: initial; min-height: 40px; padding: 5px 4px; display: flex; flex-wrap: wrap; gap: 5px; }
.error { background-color: orange; color: white; }
</style>