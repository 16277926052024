<template>
	<FieldSet id="additionalSettings" infoText="additionalSettingsInfo" required >
		<AddButton @click="addAdditionalSettings" buttonText="additionalSettings" dataCy="add-additional-settings"/>
		<SectionTable
			hideFooter
			:items="modelValue"
			typeName="PersonAmountPerAgeGroup"
			:columns="[
				{ id: 'type', type: 'slot', fieldName: 'ageGroup' },
				{ id: 'min', type: 'field', fieldName: 'min' },
				{ id: 'max', type: 'field', fieldName: 'max' },
				{ id: '', type: 'options', width: '0%' },
			]"
			:options="[
				{ label: $t('text.delete'), icon: 'mdi-delete', action: 'delete' },
			]"
			@option-delete="deleteLine"
			no-data-text="noData"
		>
			<template #column-type="{ item }">
				<AgeGroupField v-model="item.fields.ageGroup" />
			</template>
		</SectionTable>

		<!--<EditableTable
			v-model="model"
			:columns="[ 'ageGroup', 'min', 'max' ]"
			typeId="PersonAmountPerAgeGroup"
			no-data-text="noAdditionalSettingsSet"
		>
			<template #deleteItem="{ item }">
				<v-icon class="deleteIcon" @click="(action) => handleOptionClick(item.sys.id, 'delete')">mdi-delete</v-icon>
			</template>
		</EditableTable>-->
		
		<DialogV2
			ref="upsertDialog"
			:height="'460px'"
			:width="'740px'"
			:title="$t('text.upsertAdditionalSettings')"
			:confirmLabel="$t('text.confirmSelection')"
			:onConfirm="confirmDialog"
		>
			<template #content>
				<FieldWrap :title="$t('text.placeHolderAgeGroup')">
					<v-select
						variant="outlined"
						density="compact"
						return-object
						hide-details
						:placeholder="$t('text.placeHolderAgeGroup')"
						:items="ageGroups"
						:item-title="(item) => item?.[serviceLocale]"
						v-model="additionalSetting.ageGroup"
					/>
					<!-- TODO: make dropdown work with mode-driven approach -->
					<!-- <Field typeName="PersonAmountPerAgeGroup" fieldName="ageGroup" v-model="additionalSetting.ageGroup"
						:options="ageGroups"
					/> -->
				</FieldWrap>
				<FieldWrap :title="$t('text.minPersons')">
					<!-- <v-text-field
						variant="outlined"
						type="number"
						density="compact"
						hide-details
						v-model="additionalSetting.min.de"
						:min="0"
						:suffix="$t('text.persons')"
					/> -->
					<!-- TODO: the single line field model seems to always want a locale since it's bound to model[locale] underneath. What should we do for the properties that only have .de and not every locale? should these even have a .de (i.e.: min, max)? -->
					<Field typeName="PersonAmountPerAgeGroup" fieldName="min" v-model="additionalSetting.min" />
				</FieldWrap>
				<FieldWrap :title="$t('text.maxPersons')">
					<!-- <v-text-field
						variant="outlined"
						type="number"
						density="compact"
						hide-details
						v-model="additionalSetting.max.de"
						:min="0"
						:suffix="$t('text.persons')"
					/> -->
					<Field typeName="PersonAmountPerAgeGroup" fieldName="max" v-model="additionalSetting.max" />
				</FieldWrap>
			</template>
		</DialogV2>
	</FieldSet>
</template>

<script>
import Common from '@/mixins/Common.vue'
import EditableTable from './EditableTable.vue'
import AddButton from './AddButton.vue'
import FieldSet from './FieldSet.vue'
import DialogV2 from '../../../components/common/DialogV2.vue'
import FieldWrap from '../../../components/common/FieldWrap.vue'
import Field from '../../../components/fields/Field.vue'
import SectionTable from './SectionTable.vue'
import AgeGroupField from '../../../components/fields/AgeGroupField.vue'

export default {
	name: 'AdditionalSettings',
	components: { EditableTable, FieldSet, AddButton, DialogV2, FieldWrap, Field, SectionTable, AgeGroupField },
	mixins: [ Common ],
	props: {
		modelValue: Array,
	},
	data() {
		return {
			/*model: [
				{
					sys: { id: '1' },
					fields: {
						ageGroup: { de: '1' },
						min: { de: 0 },
						max: { de: 2 },
					},
				},
				{
					sys: { id: '2' },
					fields: {
						ageGroup: { de: '2' },
						min: { de: 0 },
						max: { de: 2 },	
					}
				},
				{
					sys: { id: '3' },
					fields: {
						ageGroup: { de: '3' },
						min: { de: 0, },
						max: { de: 3 },
					}
				},
			],*/
			additionalSetting: {},
		}
	},
	computed: {
/*		headers() {
			// return [
			// 	{ text: this.$t('text.ageGroup'), value: 'ageGroup', type: 'string' },
			// 	{ text: this.$t('text.min'), value: 'min', type: 'number' },
			// 	{ text: this.$t('text.max'), value: 'max', type: 'number' },
			// 	{ text: '', value: 'options', type: 'options', },
			// ]
			// only return the id and type, the child component will take care of localization
			return [ 
				{ id: 'ageGroup', type: 'dropdown', options: this.ageGroups },
				{ id: 'min', type: 'number', },
				{ id: 'max', type: 'number', },
				{ id: '', type: 'options', width: '0%' },
			]
		},*/
		tableItems() {
			return this.model.map((item) => ({
				sys: item.sys,
				ageGroup: item.ageGroup,
				min: item.min.de,
				max: item.max.de,
			}))
		},
		ageGroups() {
			// TODO: for now static model, is this supposed to be filled from the tickets in the section above it?
			return [
				{
					de: 'Adult DE (> 18)',
					en: 'Adult en (> 18)',
				},
				{
					de: 'Children DE (0 - 14)',
					en: 'Children EN (0 - 14)',
				},
				{
					de: 'Teen DE (14 - 18)',
					en: 'Teen EN (14 - 18)',
				},
			]
		},
	},
	methods: {
		additionalSettingsFactory() {
			return {
				sys: {
					id: 'id_' + Math.random().toString(36).substr(2, 9),
				},
				fields: {
					ageGroup: { de: '' },
					min: { de: 0 },
					max: { de: 0 },
				}
			}
		},
		addAdditionalSettings() {
			this.additionalSetting = this.additionalSettingsFactory()
			// this.$refs.upsertDialog.open()
			this.modelValue.push(this.additionalSetting)
		},
		async deleteLine(item) {
			const index = this.modelValue.findIndex((i) => i.sys.id === item.sys.id)
			if (index !== -1) {
				this.modelValue.splice(index, 1)
			}
		},
		/*updateTableItems(items) {
			// TODO: map the items back to the model vs just overwriting the model with the items
			console.log('updateTableItems', items)
			this.model = items.map((item) => {
				return {
					sys: item.sys,
					ageGroup: item.ageGroup,
					min: { de: item.min },
					max: { de: item.max },
				}
			})
		},*/
		confirmDialog() {
			this.upsertAdditionalSettings()
		},
		upsertAdditionalSettings() {
			if (this.additionalSetting.sys.id) {
				const index = this.modelValue.findIndex((item) => item.sys.id === this.additionalSetting.sys.id)
				this.modelValue.splice(index, 1, this.additionalSetting)
			}
			else {
				this.additionalSetting.sys.id = this.model.length + 1
				this.modelValue.push(this.additionalSetting)
			}
		},
	},
}
</script>

<style scoped>
.deleteIcon:hover {
	transform: scale(1.2);
	transition: transform 0.2s ease-in-out;
}
</style>