<template>
	<DialogV2 ref="dialog"
		:title="$t('text.editBaseProductContent')"
		:closeOnConfirm="false"
		:onConfirm="persistContentForBaseProduct"
		:onCancel="close"
		hideClose
		width="70vw"
		height="95vh"
		:loading="loading"
	>
		<template #sidebar>
			<LanguageSidebar :checkIfHasMissingTranslations="checkIfHasMissingTranslations" />
		</template>
		<template #content>
			<v-snackbar v-model="snackbar" :color="snackbarColor" location="top">
				{{ $t('text.' + snackbarText) }}
			</v-snackbar>
			<div class="gapped" :class="{ overlayed: loading }" v-if="content">
				<FieldSet id="globalSetting" mdiIcon="earth" style="padding-bottom: 20px;"></FieldSet>
				<FieldSet id="generalSettings" >
					<TranslateableField typeName="BaseProductContent" fieldName="name" v-model="content.name"
						:fieldLocale="$store.state.activeTranslation" :locales="displayedLocales"
					/>
				</FieldSet>
				<FieldSet id="publicInfo" >
					<TranslateableField typeName="BaseProductContent" fieldName="shortDescription" v-model="content.shortDescription"
						:fieldLocale="$store.state.activeTranslation" :locales="displayedLocales"
					/>
					<TranslateableField typeName="BaseProductContent" fieldName="description" v-model="content.description"
						:fieldLocale="$store.state.activeTranslation" :locales="displayedLocales"
					/>
					<!-- TODO: implement media gallery. render the images by url and check if you can reuse the existing media upload component. Otherwise implement a new control for uploading an image that returns the url only. -->
					<div>
						<v-label>{{ $t('text.mediaGallery') }}</v-label>
						<!-- TODO: images seem more complicated. To reuse the ImagesField component we either have to make lots of changes to it and all its internal components or we have to use the same structure of media/mediaAsset as we use on the MYS products. The thing is we anyway have to create such a structure when exporting to CH. -->
						<ImagesField ref="imageField" v-model="images" @on-image-errors="onImageErrors" @toggle-loading="toggleLoading" required />
						<div class="expanded-content-images">
							<!-- <v-img class="expanded-content-image" v-for="(media, index) in modelValue.addl?.peak?.mediaGallery" :key="index" :src="media" width="90" height="90" cover @error="handleImageError(index)"></v-img> -->
							<!-- <v-icon size="48px" color="#9da0a5">mdi-plus</v-icon> -->
							<!-- TODO: file upload that will make an API call that will return an uploaded image url -->
						</div>
					</div>
				</FieldSet>
				<FieldSet class="gapped" id="generalInfo" >
					<div class="excludeFrom">
						<v-checkbox v-model="excludeFrom" :value="ExcludeFromOptions.Search" :label="$t('text.excludeFromSearch')" />
						<v-checkbox v-model="excludeFrom" :value="ExcludeFromOptions.Map" :label="$t('text.excludeFromMap')" />
						<v-checkbox v-model="excludeFrom" :value="ExcludeFromOptions.DynamicContentGrids" :label="$t('text.excludeFromDynamicGrids')" />
					</div>
				</FieldSet>
			</div>
		</template>
	</DialogV2>
</template>

<script lang="ts">
import DialogV2 from '../../../components/common/DialogV2.vue'
import LanguageSidebar from '../../../components/common/LanguageSidebar.vue'
import TranslateableField from '../../../components/fields/TranslateableField.vue'
import FieldSet from './FieldSet.vue'
import LanguagesNavigation from '../../../mixins/LanguagesNavigation.vue'
import ImagesField from '../../../components/common/ImagesField.vue'
import ProductContentExecutive, { Content } from '../../../../../shared/ProductContentExecutive'
import Common from '../../../mixins/Common.vue'

export default {
	mixins: [ LanguagesNavigation, Common ],
	components: { DialogV2, FieldSet, TranslateableField, LanguageSidebar, ImagesField },
	props: {
		id: String, // Content-Hub id: may be a non-existing id
		sku: String,
		contentMissing: Boolean,
		modelValue: Object, // Package
//		modelValue: Object, // <AddedValue>
	},
	data: () => ({
		content: null, // <Content>
		snackbar: null,
		snackbarColor: null,
		snackbarText: null,
		ExcludeFromOptions: {
			Search: 'on-site search',
			Map: 'map',
			DynamicContentGrids: 'dynamic grid',
		},
		images: null,
	}),
	computed: {
		displayedLocales() {
			return this.languageNavigationItems?.reduce((locales, { code }) => {
				if (code !== 'all' && (this.$store.state.activeTranslation === 'all' || this.$store.state.activeTranslation === code)) {
					locales.push(code)
				}
				return locales
			}, [])
		},
		excludeFrom: {
			get() {
				if (!this.content) return []
				return Object.values(this.ExcludeFromOptions).filter(option => this.content.excludeFrom?.includes(option))
			},
			set(value) {
				if (!this.content) return
				this.content.excludeFrom = value
			}
		},
	},
	watch: {
		content() {
			this.contentToImages()
		},
		images: {
			deep: true,
			handler(value) {
				// TODO: it's not coming here when I select an image
				console.log('set images', value)
				const dl = 'de'
				if (!this.content) return
				this.content.images = value.map(image => ({
					id: image.sys.id,
					url: image.fields.media[ dl ].fields.file[ dl ].url,
				}))
			},
		},
	},
	methods: {
		contentToImages() {
			// it is ok to hardcode to de here as we only do this as an intermediate step
			const dl = 'de'
			if (!this.content?.images) return
			this.images = this.content.images.map(image => ({
				sys: {
					id: image.id,
				},
				fields: {
					media: {
						[ dl ]: {
							fields: {
								file: {
									[ dl ]: {
										url: image.url,
									},
								},
							},
						},
					},
				},
			}))
		},
		async persistContentForBaseProduct() {
			console.log('persistContentForBaseProduct', this.id)
			this.loading = true
			try {
				console.log('...updating product content', this.content, this.id)
				let executive = new ProductContentExecutive({ clientId: this.$store.state.selectedClient.sys.id }, this)
				if (!this.content.id) this.content.id = this.id
				this.content.serviceProviderId = this.$store.state.selectedServiceProvider?.sys?.id
				console.log('websites ---- ', this.modelValue)
				this.content.websiteIds = this.modelValue.fields.websites?.de?.map(website => website.id)
				await executive.updateProductContent(this.content)
				if (this.contentMissing) {
					console.log('...updating peak product content id', this.sku, this.id)
					await executive.updatePeakProductContentId(this.sku, this.id)
				}
				
				await this.setSnackBar('SUCCESS')
				this.$emit('finished')
				this.$emit('close')
				this.$refs.dialog.close()
			}
			catch (error) {
				console.error('Failed to persist content for base product:', error)
				await this.setSnackBar('ERROR')
			}
			finally {
				this.loading = false
			}
		},
		open() {
			this.$refs.dialog.open()
		},
		close() {
			this.$refs.dialog.close()
			this.$emit('close')
		},
		checkIfHasMissingTranslations(locale) {
			if (!this.content) return false
			const { name, shortDescription, description } = this.content
			return !name?.[locale] || !shortDescription?.[locale] || !description?.[locale]
		},
		async setSnackBar(text) {
			this.snackbarText = text
			this.snackbarColor = text === 'SUCCESS' ? 'success' : 'error'
			this.snackbar = true
			const timeout = text === 'SUCCESS' ? 1000 : 2000
			setTimeout(() => { this.snackbar = false }, timeout)
			await new Promise(resolve => setTimeout(resolve, timeout))
		},
		toggleLoading(value) {
			this.loading = value
		},
		onImageErrors(errors) {
			console.log('onImageErrors', errors)
			if (typeof errors == 'object' && Object.keys(errors).length === 0) return
			this.setSnackBar(errors)
		},
	},
	async mounted() {
		this.loading = true
		const executive = new ProductContentExecutive({ clientId: this.$store.state.selectedClient.sys.id }, this)
		this.content = await executive.getProductContent(this.id)
		if (!this.content?.exists) {
			this.content = await executive.getPeakProductContentForSku(this.sku)
		}
		this.contentToImages()
		this.loading = false
	},
}
</script>

<style scoped>
.gapped { display: flex; flex-direction: column; gap: 16px; }
.excludeFrom { display: flex; flex-direction: row; gap: 16px; }
</style>