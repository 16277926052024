<script>
export default {
	methods: {
		async getApplications() {
			const allApplications = await this.$httpGet(`/applications`)
			return this.$store.commit('setAllApplications', allApplications)
		},
		async getFeatureFlags() {
			const featureFlags = await this.$httpGet(`/feature-flags`)
			return this.$store.commit('setFeatureFlags', featureFlags)
		},
		async setServiceProviderData(user) {
			return Promise.all([this.$store.commit('setSelectedServiceProvider', user.fields.serviceProvider.de), this.$store.commit('setSelectedClient', user.fields.clients.de[0])])
		},
		async setOperatorData(user) {
			const selectedClient = user.fields.clients.de[0]
			const components = await this.$httpGet(`/components/${selectedClient.sys.id}/serviceprovider`)
			components.sort((a, b) => (a.fields.title[this.selectedLocale] > b.fields.title[this.selectedLocale]) ? 1 : -1)

			return Promise.all([
				this.$store.commit('setSelectedClient', selectedClient),
				this.$store.commit('setSPApplications', components),
			])
		},
		async setAdminData(user) {
			return this.$store.commit('setSelectedClient', user.fields.clients.de[0])
		},
		async login(username, password) {
			try {
				const user = await this.$httpPost('/login', { username, password })

				if (!user?.kc_token) this.showError(this.$t('text.loginInvalidCredentialsError'))

				// Sort according to personal dashboard
				const components = user.fields.applications.de

				await Promise.all([this.$store.commit('setComponents', components), this.$store.commit('setLoggedInUser', user), this.getApplications(), this.getFeatureFlags()])

				if (!user.fields.clients?.de) {
					console.error("user does not have any clients set")
				}

				if (user.fields.type.de === "serviceprovider") {
					await this.setServiceProviderData(user)
				}
				else if (user.fields.type.de === "operator") {
					await this.setOperatorData(user)
				}
				else if (user.fields.type.de === "admin") {
					await this.setAdminData(user)
				}
				else {
					console.error("received user with unsupported type:", user.fields.type.de)
					return true
				}

				return true
			}
			catch (error) {
				if (error.error) {
					this.showError(error.error)
				}
				else if (error.message) {
					console.error(error.message)
					this.showError(error.message)
				}
				else {
					// TODO: this message is likely never correct, as the server should always return error.error,
					//       so any other error will be local or network related.
					this.showError(this.$t('text.loginInvalidCredentialsError'))
				}
			}
		},
	},
}
</script>
